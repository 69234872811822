import BotaoLink from 'components/Botao/BotaoLink';
import DescricaoComponent from 'components/DescricaoComponent';
import Subtitulo from 'components/Subtitulo';
import Titulo from 'components/Titulo';
import TituloPequeno from 'components/TituloPequeno';
import style from './NoticiaInicio.module.scss';

import ImageGallery from 'react-image-gallery';

interface Props {
    listNoticias: {
        id: number,
        titulo: string,
        subtitulo: string,
        indiceImagens: number
    }[]
}

interface Noticia {
    id: number,
    titulo: string,
    subtitulo: string,
    indiceImagens: number
}

function getImagens(id: number, indice: number) {

    if (indice === 0) {
        indice = 1;
    }
    
    const imagens = [{original: '', thumbnail: ''}];
    imagens.pop();

    for (let i = 1; i <= indice; i++) {

        const imagem = {
            original: 'https://www.grupocappelletto.com//src/assets/fotos/noticias/n'+ id + '-' + i +'.png',
            thumbnail: 'https://www.grupocappelletto.com//src/assets/fotos/noticias/n'+ id + '-' +  i +'.png',
        };

        imagens.push(imagem);
    }

    return imagens;
}

export default function NoticiaInicio(props: Props) {
    return(
        <section className={style.noticia}>

            <Titulo titulo={'Notícias'} color={'black'}/>
            <Subtitulo titulo={'Confira as últimas notícias do Grupo Cappelletto.'}/>

            <section className={style.noticia__lista}>
                <div className={style.first}>

                    {props.listNoticias.slice(0,6).map(item => (

                        <div className={style.first__box} key={item.id}>

                            <div className={style.first__box__titulo}>
                                <TituloPequeno 
                                    titulo={item.titulo} 
                                    to={`/noticias/detail/${item.id}`}
                                    top={'min'}
                                    align={'left'}
                                    color={'dark'}
                                />
                            </div>

                            <div className={style.first__second__box} key={item.id}>

                                <div className={style.first__second__box__image}>
                                    <ImageGallery 
                                        items={getImagens(item.id, item.indiceImagens)} 
                                        autoPlay={true}
                                        disableThumbnailScroll={true}
                                        showThumbnails={false}
                                        showFullscreenButton={false}
                                        showPlayButton={false}
                                        showBullets={false}
                                        showNav={false}
                                        additionalClass={style.first__second__box__image__props}
                                    />
                                </div>

                                <div className={style.first__second__box__desc}>
                                    <DescricaoComponent 
                                        descricao={item.subtitulo} 
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <BotaoLink texto={'Mais notícias'} key={'saiba'} to={'/noticias'} />
            </section>
        </section>
    );
}