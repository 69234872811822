import style from './Cadastrar.module.scss';
import tema from 'styles/Tema.module.scss';
import { useState } from 'react';
import { cadClienteCrud } from 'services/ClienteCrudDataService';
import { useNavigate, useParams } from 'react-router-dom';
import Titulo from 'components/Titulo';
import Voltar from 'components/Voltar';
import Botao from 'components/Botao';

export default function Cadastrar() {

    const navigate = useNavigate();

    const { id } = useParams();

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [celular, setCelular] = useState('');
    const [telefone, setTelefone] = useState('');

    async function cadastrar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await cadClienteCrud({
            'nome': nome,
            'sobrenome': sobrenome,
            'idCliente': id
        }
        ).then((sucesso) => {
            clearForm();
            setTypeMessage(1);
            navigate(`/admin/cliente/alterar/${'a' + sucesso.data.id}`);
        }).catch((erro) => {
            const message = erro.message;
            if (message.includes('406')) {
                setMessage('E-mail já cadastrado para outro cliente.');
            }
            setTypeMessage(2);  
        });
    }

    function clearForm() {
        setNome('');
        setSobrenome('');
        setEmail('');
        setSenha('');
        setCelular('');
        setTelefone('');
    }

    return (
        <section >
            <Titulo titulo={'Cadastrar Cliente'} color={'black'}/>
            <form onSubmit={cadastrar} className={style.cadastrar}>
                <div className={tema.boxDefault}>
                    <p>
                        <label className={tema.label}>
                        Nome/Razão
                        </label>
                    </p>
                    <input
                        className={tema.input}
                        type="text"
                        value={nome}
                        maxLength={70}
                        required
                        placeholder="Nome/Razão do cliente"
                        onChange={(e) => setNome(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Fantasia
                        </label>
                    </p>

                    <input
                        className={tema.input}
                        type="text"
                        value={sobrenome}
                        required
                        maxLength={70}
                        placeholder="Nome fantasia do cliente"
                        onChange={(e) => setSobrenome(e.target.value)}
                    />

                </div>
                <footer className={tema.footer}>     
                    <Voltar/>
                    <Botao type="submit">
                        Cadastrar
                    </Botao>
                </footer>
            </form>
        </section>
    );
}