import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getCrudClientes = async () => {
    return (await http.get('/clienteCrud'));
};

export const getClienteCrudById = async(id: number) => {
    return (await http.get(`/clienteCrud/details/${id}`));
};

export const getClientePerfilById = async(id: number) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get(`/clienteCrud/perfil/${id}`, {headers}));
};


export const getClienteCrudByEmail = async(email: string | null) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get(`/clienteCrud/email?email=${email}`, {headers}));
};

export const cadClienteCrud = async (data: any) => {   
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    }; 
    return (await http.post('/clienteCrud', data, {headers}));
};

export const altClienteCrud = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/clienteCrud/${id}`, data, {headers}));
};

export const delCliente = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    }; 
    return (await http.delete(`/clienteCrud/${id}`, {headers})).status;
};

export const uploadFoto = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/clienteCrud/upload?id=${id}`, data, {headers}));
};
