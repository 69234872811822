import style from './SocialHeader.module.scss';
import { TiSocialFacebookCircular } from 'react-icons/ti';
import { FiTwitter } from 'react-icons/fi';
import { BsInstagram } from 'react-icons/bs';


export default function socialHeader() {
    return (
        <div className={style.socialHeader}>
            <a href='https://www.facebook.com/grupocappelletto/' className={style.socialHeader__icon}>
                <TiSocialFacebookCircular size={33} />
            </a>
            <a href='https://www.instagram.com/grupocappelletto/' className={style.socialHeader__icon2}>
                <FiTwitter size={27} />
            </a>
            <a href='https://www.instagram.com/grupocappelletto/' className={style.socialHeader__icon3}>
                <BsInstagram size={25} />
            </a>
        </div>
    );
}