import style from './Cadastrar.module.scss';
import tema from 'styles/Tema.module.scss';
import { useEffect, useState } from 'react';
import { cadFuncionario } from 'services/FuncionarioDataService';
import { useNavigate } from 'react-router-dom';
import Titulo from 'components/Titulo';
import Voltar from 'components/Voltar';
import Botao from 'components/Botao';

export default function Cadastrar() {

    const navigate = useNavigate();


    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [cargo, setCargo] = useState('');
    const [descricao, setDescricao] = useState('');

    async function cadastrar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await cadFuncionario({
            'nome': nome,
            'sobrenome': sobrenome,
            'descricao': descricao.toLowerCase().trim(),
            'telefone': telefone,
            'email': email,
            'cargo': cargo
        }
        ).then((sucesso) => {
            clearForm();
            setTypeMessage(1);
            navigate(`/admin/funcionarios/alterar/${'a' + sucesso.data.id}`);
        }).catch((erro) => {
            const message = erro.message;
            setMessage(message);
            setTypeMessage(2);  
        });
    }

    function clearForm() {
        setNome('');
        setSobrenome('');
        setDescricao('');
        setCargo('');
        setEmail('');
        setTelefone('');
    }

    return (
        <section >
            <Titulo titulo={'Cadastrar Funcionário'} color={'black'}/>
            <form onSubmit={cadastrar} className={style.cadastrar}>
                <div className={tema.boxDefault}>
                    <p>
                        <label className={tema.label}>
                        Nome
                        </label>
                    </p>
                    <input
                        className={tema.input}
                        type="text"
                        value={nome}
                        maxLength={50}
                        required
                        placeholder="Nome do funcionário"
                        onChange={(e) => setNome(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Sobrenome
                        </label>
                    </p>

                    <input
                        className={tema.input}
                        type="text"
                        value={sobrenome}
                        required
                        maxLength={100}
                        placeholder="Sobrenome do funcionário"
                        onChange={(e) => setSobrenome(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                            E-mail
                        </label>
                    </p>
                    <input
                        className={tema.input}
                        type="text"
                        value={email}
                        maxLength={50}
                        required
                        placeholder="E-mail do funcionário"
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Telefone
                        </label>
                    </p>
                    <input
                        className={tema.input}
                        type="text"
                        value={telefone}
                        maxLength={50}
                        required
                        placeholder="Telefone do funcionário"
                        onChange={(e) => setTelefone(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Cargo
                        </label>
                    </p>
                    <input
                        className={tema.input}
                        type="text"
                        value={cargo}
                        maxLength={50}
                        required
                        placeholder="Cargo do funcionário"
                        onChange={(e) => setCargo(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Descrição
                        </label>
                    </p>

                    <textarea
                        className={tema.inputText}
                        value={descricao}
                        rows={5}
                        cols={33}
                        required
                        placeholder="Descricao do cargo"
                        onChange={(e) => setDescricao(e.target.value)}
                    />

                </div>
                <footer className={tema.footer}>     
                    <Voltar/>
                    <Botao type="submit">
                        Cadastrar
                    </Botao>
                </footer>
            </form>
        </section>
    );
}