import style from './Funcionario.module.scss';
import tema from 'styles/Tema.module.scss';
import BotaoTela from 'components/Botao/BotaoTela';
import Titulo from 'components/Titulo';


export default function Funcionario() {
    
    return(
        <div className={tema.customContainer}>
            <Titulo titulo={'Funcionários'} color={'black'} />
            <section className={style.funcionario}>
                
                <BotaoTela 
                    to={'/admin/funcionarios/pesquisar'}
                    texto={'Pesquisar'}
                    icon={'Pesquisar'}
                />

                <BotaoTela 
                    to={'/admin/funcionarios/cadastrar'}
                    texto={'Cadastrar'}
                    icon={'Cadastrar'}
                />

            </section>
        </div>
    );
}