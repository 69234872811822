
import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getSobres = async () => {
    return (await http.get('/sobre'));
};

export const getSobreByTipo = async(tipo: number) => {
    return (await http.get(`/sobre/details/${tipo}`));
};


export const cadSobre = async (data: any) => {    
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.post('/sobre', data, {headers}));
};

export const altSobre = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/sobre/${id}`, data, {headers}));
};

export const delSobre = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    }; 
    return (await http.delete(`/sobre/${id}`, {headers})).status;
};

export const uploadFoto = async (tipo: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/sobre/upload?tipo=${tipo}`, data, {headers}));
};