import style from './Alterar.module.scss';
import tema from 'styles/Tema.module.scss';
import Botao from 'components/Botao';
import { useEffect, useState } from 'react';
import { altDepoimento, getDepoimentoById, uploadFoto} from 'services/DepoimentoDataService';
import { useNavigate, useParams } from 'react-router-dom';
import { isUserLogged } from 'common/utils/authUtils';
import Voltar from 'components/Voltar';
import Titulo from 'components/Titulo';
import Message from 'components/Message';
import BotaoLink from 'components/Botao/BotaoLink';

export default function Alterar() {

    let { id } = useParams();

    const [depoimentoSalvo, setDados] = useState(
        {
            'nome': '',
            'descricao': '',
            'depoimento': ''
        }
    );

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [depoimento, setDepoimento] = useState('');

    const navigate = useNavigate();
    function verificaDepoimentoLogado() {
        if (isUserLogged() === false) {
            return navigate('/login');
        }
    }

    function verifId() {
        return id?.startsWith('a');
    }

    useEffect(() => {
        if (verifId()) {
            setMessage('Depoimento cadastrada com sucesso.');
            id = id?.substring(1);
        }
        verificaDepoimentoLogado();
        getDepoimento();
    },[id]);

    async function getDepoimento() {
        const depoimento = await getDepoimentoById(Number(id));
        setDados(depoimento.data);
        setNome(depoimento.data.nome);
        setDepoimento(depoimento.data.depoimento);
        setDescricao(depoimento.data.descricao);
    }


    async function alterar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await altDepoimento(
            Number(id),
            {
                'nome': nome,
                'descricao': descricao,
                'depoimento': depoimento.toLowerCase().trim()
            }
        ).then((sucesso) => {

            setMessage('Depoimento alterado com sucesso.');
            setTypeMessage(1);

            localStorage.setItem('userMail', descricao);

            navigate('/cadastro-realizado/2');
        }).catch((erro) => {
            const message = erro.message;
            if (message.includes('406')) {
                setMessage('E-mail já cadastrado para outro depoimento.');
            }
            setTypeMessage(2);  
        });
    }

    const onFileChangeHandler = async (e: any) => {
        
        if (e[0].size > 1048576) {
            setMessage('O tamanho da imagem não dever ultrapassar o limite máximo de 1mb.');
            setTypeMessage(2);
            return;
        }

        const fotoEmpresa = {
            selectedFile: e[0]
        };
        
        const formData = new FormData();
        
        formData.append('file', fotoEmpresa.selectedFile);

        if (verifId()) {
            id = id?.substring(1);
        }
        const response = await uploadFoto(Number(id), formData)
            .then((sucesso) => {
                setMessage('Imagem enviada com sucesso');
            }).catch((erro) => {
                setMessage(erro.message);
                setTypeMessage(2);
            });
    };

    return (
        <form onSubmit={alterar} className={style.alterar}>
            <Message type={1} message={message} />

            <Titulo titulo={'Alterar Depoimento'} color={'black'} />

            <section className={style.alterar__form}>

                <div className={style.alterar__form__foto}>
                    <img className={style.alterar__form__foto__imagem} src={`https://www.grupocappelletto.com//src/assets/fotos/depoimentos/d${id}.png`} alt={'Imagem da pessoa'} />

                    <label className={style.alterar__form__foto__input__label}>
                        <input 
                            className={style.alterar__form__foto__input}
                            type="file" 
                            name="foto"
                            accept='image/jpg,image/png'
                            onChange={async (e) => 
                            {
                                await onFileChangeHandler(e.target.files);
                            }}
                        />
                        Enviar imagem 
                    </label>
                </div>

                <div className={tema.boxDefaultHorizontal}>
                    <p>
                        <label className={tema.label}>
                        Nome da pessoa
                        </label>
                    </p>
                    <p>
                        <input
                            className={tema.input}
                            type="text"
                            value={nome}
                            maxLength={50}
                            required
                            placeholder="Nome da pessoa"
                            onChange={(e) => setNome(e.target.value)}
                        />
                    </p>

                    <p>
                        <label className={tema.label}>
                        Descrição
                        </label>
                    </p>

                    <p>
                        <input
                            className={tema.input}
                            type="text"
                            value={descricao}
                            required
                            maxLength={100}
                            placeholder="Cargo, título ou descrição da pessoa"
                            onChange={(e) => setDescricao(e.target.value)}
                        />
                    </p>

                    <p>
                        <label className={tema.label}>
                        Depoimento
                        </label>
                    </p>

                    <textarea
                        className={tema.inputText}
                        value={depoimento}
                        rows={5}
                        cols={33}
                        required
                        placeholder="Depoimento"
                        onChange={(e) => setDescricao(e.target.value)}
                    />                </div>                
            </section>
            <footer className={tema.footer}>
                <Voltar/>
                <Botao type="submit">
                    Alterar
                </Botao>
                <BotaoLink to={`/admin/depoimentos/excluir/${id}`} texto={'Excluir'}/>
            </footer>
        </form>
    );
}