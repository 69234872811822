import { v4 as uuidv4} from 'uuid';

export function getUserLogged() {
    const email = localStorage.getItem('userMail');
    return email !== undefined && email !== null ? email : 'notfound';
}

export function isUserLogged() {
    const userMail = localStorage.getItem('userMail');
    return !userMail?.startsWith('guest') && userMail !== undefined && userMail !== null && userMail !== '';
}

export function getCappellettoGrupo() {
    const cappellettoGrupo = localStorage.getItem('cappellettoGrupo');
    if (cappellettoGrupo !== undefined && cappellettoGrupo != null && cappellettoGrupo !== '') {
        return (Number(cappellettoGrupo.substring(1)) - 300);
    }
    else {
        return 0;
    }
}

export function getGuestId() {
    return 'guest-' + uuidv4();
}

export function setExodus(token: string) {
    localStorage.setItem('3x0du5', token.replaceAll('M','M@').replaceAll('T','T#').replaceAll('Y','Y$').replaceAll('Z','Zabynder'));
}

export function getToken() {

    const exodus = localStorage.getItem('3x0du5');

    const finalizado = exodus !== null ? 
        exodus.replaceAll('M@','M').replaceAll('T#','T').replaceAll('Y$','Y').replaceAll('Zabynder','Z') : 'notfound';
    
    return finalizado;
}

export function getLoad() {
    const load = localStorage.getItem('cappellettoGrupoLoad');
    return load !== null ? load : undefined;
}

export function isLoadBroken() {
    const load = getLoad();
    return isUserLogged() && (load === undefined || Number(load) !== new Date().getDay());
}