import DescricaoComponent from 'components/DescricaoComponent';
import Titulo from 'components/Titulo';
import { useEffect, useState } from 'react';
import { getOndeEstamosDetails } from 'services/OndeEstamosDataService';
import style from './Page.module.scss';
import parse from 'html-react-parser';

export default function Page() {

    const [template] = [{
        id: 0,
        titulo: '',
        subtitulo: '',
        descricao: ''
    }];    

    const [id, setId] = useState();

    const [dataOndeEstamos, setDataOndeEstamos] = useState(template);

    async function getDataOndeEstamos() {
        const dataOndeEstamos = await getOndeEstamosDetails();
        dataOndeEstamos.data.id = 1;
        setDataOndeEstamos(dataOndeEstamos.data);
    }

    useEffect(() => {
        getDataOndeEstamos();
    },[id]);

    return(
        <section className={style.ondeEstamos}>
            <Titulo
                titulo={dataOndeEstamos.titulo} 
                color={'black'}
            />
            <DescricaoComponent
                descricao={dataOndeEstamos.subtitulo} 
                textalign={'center'}
                size={'medium'}
            />
            <section className={style.ondeEstamos__lista}>
                <div className={style.first}>
                    <section className={style.noticia__lista}>
                        <div className={style.first}>
                            <div className={style.first__box}>
                                <img className={style.first__box__image}
                                    src={`https://www.grupocappelletto.com//src/assets/fotos/ondeEstamos/i${dataOndeEstamos.id}.png`}
                                    alt={'Empresa'}
                                />
                            </div>
                            <div className={style.first__box}>
                                {parse(dataOndeEstamos.descricao)}
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </section>
    );
}