
import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getUsuarios = async () => {
    return (await http.get('/usuarios'));
};

export const getUsuarioById = async(id: number) => {
    return (await http.get(`/usuarios/details/${id}`));
};

export const getUsuarioPerfilById = async(id: number) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get(`/usuarios/perfil/${id}`, {headers}));
};

export const getUsuarioByEmail = async(email: string | null) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get(`/usuarios?email=${email}`, {headers}));
};

export const getUsuarioTermosDeUso = async(email: string | null) => {
    return (await http.get(`/usuarios/usuario-termos/0?email=${email}`));
};

export const aceitarTermos = async(id: number | null) => {
    return (await http.put(`/usuarios/aceitar-termos/${id}`));
};

export const getUsuariosPendentes = async() => {
    return (await http.get('/usuarios?status=P'));
};

export const cadUsuario = async (data: any) => {    
    return (await http.post('/usuarios', data));
};

export const altUsuario = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/usuarios/${id}`, data, {headers}));
};

export const altSenha = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/usuarios/alterar-senha/${id}`, data, {headers}));
};

export const aprovarUsuario = async (id: number, data: any, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    };
    return (await http.put(`/usuarios/aprovar/${id}`, data, {headers}));
};

export const delUsuario = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    }; 
    return (await http.delete(`/usuarios/${id}`, {headers})).status;
};

export const reportarErro = async (data: any) => {
    return (await http.post('/usuarios/reportar-erro/', data));
};