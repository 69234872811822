import DescricaoComponent from 'components/DescricaoComponent';
import Subtitulo from 'components/Subtitulo';
import Titulo from 'components/Titulo';
import TituloPequeno from 'components/TituloPequeno';
import { useState } from 'react';
import { getServicos } from 'services/ServicoDataService';
import style from './Servico.module.scss';

export default function Servico() {

    const [template] = [[{
        id: 0,
        titulo: '',
        descricao: ''
    }]];    

    const [listServicos, setListServicos] = useState(template);

    async function getListServicos() {
        const listServicos = await getServicos();
        setListServicos(listServicos.data.content);
    }

    useState(() => {
        getListServicos();
    });

    return(
        <section className={style.servico}>
            <Titulo titulo={'Serviços'} color={'black'} />
            <Subtitulo titulo={'Confira as últimas novidades sobre o Grupo Cappelletto.'} />
            
            <section className={style.servico__lista}>
                <div className={style.first}>
                    {listServicos.map(item => (
                        <div className={style.first__box} key={item.id}>
                            <div className={style.first__box__title} key={item.id}>
                                <TituloPequeno
                                    titulo={item.titulo} 
                                    bottom={'max'}
                                    top={'min'}
                                    align={'normal'}
                                    color={'dark'}
                                />
                                <img className={style.first__box__image}
                                    src={`https://www.grupocappelletto.com//src/assets/fotos/pageServicos/s${item.id}.png`}
                                    alt={'Servico'}
                                />
                            </div>
                            <div className={style.first__box__text} key={item.id}>
                                <DescricaoComponent key={item.id} descricao={item.descricao}/>
                            </div>
                        </div>
                        
                    ))}
                </div>
            </section>
        </section>
    );
}