import { getToken, isUserLogged } from 'common/utils/authUtils';
import Botao from 'components/Botao';
import BotaoTela from 'components/Botao/BotaoTela';
import Message from 'components/Message';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { delUsuario } from 'services/UsuarioDataService';
import style from './Excluir.module.scss';

export default function Excluir() {

    const [messageDel, setMessage] = useState('Deseja realmente remover a usuario?');
    const [typeMessage, setTypeMessage] = useState(2);
    
    const [statusVoltar, setStatusVoltar] = useState(1);

    const { id } = useParams();

    const navigate = useNavigate();
    function verificaUsuarioLogado() {
        if (isUserLogged() === false) {
            return navigate('/login');
        }
    }

    useEffect(() => {
        verificaUsuarioLogado();
    },[]);

    async function excluir() {

        const token = getToken();

        const status = await delUsuario(Number(id), token).then((sucesso) => {

            setMessage('Usuario removido com sucesso.');
            setTypeMessage(1);
            setStatusVoltar(2);

        }).catch((erro) => {
            setMessage('Ocorreu um erro ao remover a usuario.');
            setTypeMessage(2);

        });

    }

    return(
        <div className={style.excluir}>
            <Message 
                message={messageDel}
                type={typeMessage}
            />
            {
                statusVoltar === 2 ?
                    <BotaoTela to={'/admin/usuarios/pesquisar'} texto={'Voltar'} icon={'Voltar'}/>
                    :
                    <div className={style.box}>
                        <Botao type="submit" onClick={excluir}>
                            Remover
                        </Botao>
                        <BotaoTela to={`/admin/usuarios/alterar/${id}`} texto={'Voltar'} styleAlt={style.botaoExcluir} icon={'Voltar'}/>
                    </div>
            }
        </div>
    );
}