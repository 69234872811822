import Voltar from 'components/Voltar';
import style from './MessageCadastrado.module.scss';
import imgOk from 'assets/img/ok.png';
import VoltarTela from 'components/Voltar/VoltarTela';
import { useParams } from 'react-router-dom';

export default function MessageCadastrado() {

    const { id } = useParams();

    return(
        <>
            <div className={style.messageCadastrado}>
                <img src={imgOk} className={style.messageCadastrado__img} />
                Cadastro {id === '1' ? 'Cadastrado' : 'Alterado'} com sucesso.
                <VoltarTela/>
            </div>
            
        </>
    );
}