import style from './Alterar.module.scss';
import tema from 'styles/Tema.module.scss';
import Botao from 'components/Botao';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isUserLogged } from 'common/utils/authUtils';
import Voltar from 'components/Voltar';
import BotaoLink from 'components/Botao/BotaoLink';
import { altClienteCrud, getClienteCrudById, getClientePerfilById, uploadFoto } from 'services/ClienteCrudDataService';

export default function Alterar() {

    let { id } = useParams();

    const [cliente, setDados] = useState(
        {
            'nome': '',
            'sobrenome': ''
        }
    );

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');

    const navigate = useNavigate();
    function verificaClienteLogado() {
        if (isUserLogged() === false) {
            return navigate('/login');
        }
    }

    function verifId() {
        return id?.startsWith('a');
    }

    useEffect(() => {
        if (verifId()) {
            setMessage('Cliente cadastrado com sucesso.');
            id = id?.substring(1);
        }
        verificaClienteLogado();
        getCliente();
    },[id]);

    async function getCliente() {
        const cliente = await getClienteCrudById(Number(id));
        setDados(cliente.data);
        setNome(cliente.data.nome);
        setSobrenome(cliente.data.sobrenome);
    }

    
    async function alterar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await altClienteCrud(
            Number(id),
            {
                'nome': nome,
                'sobrenome': sobrenome
            }
        ).then((sucesso) => {

            setMessage('Cliente alterado com sucesso.');
            setTypeMessage(1);

            navigate('/cadastro-realizado/2');
        }).catch((erro) => {
            const message = erro.message;
            if (message.includes('406')) {
                setMessage('E-mail já cadastrado para outro usuário.');
            }
            setTypeMessage(2);  
        });
    }

    const onFileChangeHandler = async (e: any) => {
        
        if (e[0].size > 1048576) {
            setMessage('O tamanho da imagem não dever ultrapassar o limite máximo de 1mb.');
            setTypeMessage(2);
            return;
        }

        const fotoEmpresa = {
            selectedFile: e[0]
        };
        
        const formData = new FormData();
        
        formData.append('file', fotoEmpresa.selectedFile);

        const response = await uploadFoto(Number(id), formData)
            .then((sucesso) => {
                setMessage('Imagem enviada com sucesso');
            }).catch((erro) => {
                setMessage(erro.message);
                setTypeMessage(2);
            });
    };


    return (
        <form onSubmit={alterar} className={style.alterar}>

            <h3 className={tema.titulo}>Alterar cliente</h3>

            <section className={style.alterar__form}>

                <div className={style.alterar__form__foto}>
                    <img className={style.alterar__form__foto__imagem} src={`https://www.grupocappelletto.com//src/assets/fotos/clienteCrud/c${id}.png`} alt={'Imagem do institucional'} />

                    <label className={style.alterar__form__foto__input__label}>
                        <input 
                            className={style.alterar__form__foto__input}
                            type="file" 
                            name="foto"
                            accept='image/jpg,image/png'
                            onChange={async (e) => 
                            {
                                await onFileChangeHandler(e.target.files);
                            }}
                        />
                        Enviar imagem 
                    </label>
                </div>
                <div className={tema.boxDefaultHorizontal}>
                    <p>  
                        <label className={tema.label}>
                        Nome/Razão
                        </label>
                    </p>
                    <input
                        className={tema.input}
                        type="text"
                        value={nome}
                        maxLength={15}
                        required
                        placeholder="Nome/Razão do cliente"
                        onChange={(e) => setNome(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Sobrenome
                        </label>
                    </p>

                    <input
                        className={tema.input}
                        type="text"
                        value={sobrenome}
                        required
                        maxLength={70}
                        placeholder="Nome fantasia do cliente"
                        onChange={(e) => setSobrenome(e.target.value)}
                    />
                </div>                
            </section>
            <footer className={tema.footer}>
                <Voltar/>
                <Botao type="submit">
                    Alterar
                </Botao>
                <BotaoLink to={`/admin/cliente/excluir/${id}`} texto={'Excluir'}/>
            </footer>
        </form>
    );
}