import style from './Cadastrar.module.scss';
import tema from 'styles/Tema.module.scss';
import { useEffect, useState } from 'react';
import { cadServico } from 'services/ServicoDataService';
import { useNavigate } from 'react-router-dom';
import Titulo from 'components/Titulo';
import Voltar from 'components/Voltar';
import Botao from 'components/Botao';

export default function Cadastrar() {

    const navigate = useNavigate();


    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [descricaoResumida, setDescricaoResumida] = useState('');
    const [exibirSite, setExibirSite] = useState('N');

    async function cadastrar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await cadServico({
            'titulo': titulo,
            'descricao': descricao.trim(),
            'descricaoResumida':  descricaoResumida.trim(),
            'exibirSite': exibirSite
        }
        ).then((sucesso) => {
            clearForm();
            setTypeMessage(1);
            navigate(`/admin/servicos/alterar/${'a' + sucesso.data.id}`);
        }).catch((erro) => {
            const message = erro.message;
            setMessage(message);
            setTypeMessage(2);  
        });
    }

    function clearForm() {
        setTitulo('');
        setDescricao('');
        setDescricaoResumida('');
        setExibirSite('N');
    }

    return (
        <form onSubmit={cadastrar} className={style.cadastrar}>
            <Titulo titulo={'Cadastrar Serviço'} color={'black'}/>
            <section className={style.cadastrar__form}>
                <div className={tema.boxDefault}>
                    <p>  
                        <label className={tema.label}>
                        Título
                        </label>
                    </p>
                    <p>
                        <input
                            className={tema.input}
                            type="text"
                            value={titulo}
                            maxLength={70}
                            required
                            placeholder="Titulo do serviço"
                            onChange={(e) => setTitulo(e.target.value)}
                        />
                    </p>
                    <p>
                        <label className={tema.label}>
                        Descrição para a página inicial
                        </label>
                    </p>
                    <p>
                        <textarea
                            className={tema.inputText}
                            value={descricaoResumida}
                            required
                            maxLength={150}
                            placeholder="Descrição resumida do serviço"
                            onChange={(e) => setDescricaoResumida(e.target.value)}
                        />
                    </p>
                    <p>
                        <label className={tema.label}>
                        Descrição completa
                        </label>
                    </p>
                    <textarea
                        className={tema.inputText}
                        value={descricao}
                        required
                        maxLength={1500}
                        placeholder="Descrição do serviço"
                        onChange={(e) => setDescricao(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                            Exibir na página inicial?
                        </label>
                    </p>
                    <p>
                        <select className={tema.selCombo} value={exibirSite} onChange={(e) => setExibirSite(e.target.value)}>
                            <option key='S' value='S'>
                                Sim
                            </option>
                            <option key='N' value='N'>
                                Não
                            </option>
                        </select>
                    </p>
                </div>       
            </section>
            <footer className={tema.footer}>     
                <Voltar/>
                <Botao type="submit">
                    Cadastrar
                </Botao>
            </footer>
        </form>
    );
}