
import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getDepoimentos = async () => {
    return (await http.get('/depoimentos'));
};

export const getDepoimentoById = async(id: number) => {
    return (await http.get(`/depoimentos/details/${id}`));
};


export const getDepoimentoByTitulo = async(titulo: string | null) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get(`/depoimentos/titulo?titulo=${titulo}`, {headers}));
};


export const cadDepoimento = async (data: any) => {    
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.post('/depoimentos', data, {headers}));
};

export const altDepoimento = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/depoimentos/${id}`, data, {headers}));
};

export const delDepoimento = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    }; 
    return (await http.delete(`/depoimentos/${id}`, {headers})).status;
};

export const uploadFoto = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/depoimentos/upload?id=${id}`, data, {headers}));
};