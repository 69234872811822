import DescricaoComponent from 'components/DescricaoComponent';
import Titulo from 'components/Titulo';
import { useEffect, useState } from 'react';
import { getSobreByTipo } from 'services/SobreDataService';
import style from './Page.module.scss';

export default function Page() {

    const [template] = [{
        id: 0,
        descricao: '',
        descricaoResumida: '',
        tipo: ''
    }];    

    const [id, setId] = useState();
    const [descricao, setDescricao] = useState();
    const [descricaoResumida, setDescricaoResumida] = useState();
    const [atuacao, setAtuacao] = useState(template);

    async function getAtuacao() {
        const dataAtuacao = await getSobreByTipo(3);
        dataAtuacao.data.id = 3;
        setAtuacao(dataAtuacao.data);
        setDescricao(dataAtuacao.data.descricao);
        setDescricaoResumida(dataAtuacao.data.descricaoResumida);
    }

    useEffect(() => {
        getAtuacao();
    },[id]);

    return(
        <section className={style.ondeEstamos}>
            <Titulo
                titulo={'Atuação'} 
                color={'black'}
            />
            <section className={style.ondeEstamos__lista}>
                <div className={style.first}>
                    <section className={style.noticia__lista}>
                        <div className={style.first}>
                            <div className={style.first__box}>
                                <img className={style.first__box__image}
                                    src={`https://www.grupocappelletto.com//src/assets/fotos/sobre/s${atuacao.id}.png`}
                                    alt={'Empresa'}
                                />
                            </div>
                            <div className={style.first__box__text}>
                                <DescricaoComponent
                                    descricao={atuacao.descricao} 
                                    size={'medium'}
                                />
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </section>
    );
}