import style from './Header.module.scss';
import logo from 'assets/img/cappelletto.png';
import Menu from 'components/Menu';
import SocialHeader from './SocialHeader/SocialHeader';

export default function Header() {

    return (
        <>

            <SocialHeader />
            
            <header className={style.header}>

                <img 
                    className={style.header__logo}
                    src={logo} 
                    alt='Grupo Cappelleto' 
                />

                <section className={style.header__contato}>

                    <div className={style.header__contato__img}>
                        
                    </div>
                    <div>
                        <p className={style.header__contato__fone}>(67) 3023.1379</p>
                        <p className={style.header__contato__email}>contato@grupocappelletto.net</p>
                    </div>

                </section>

                <section className={style.header__local}>
                    
                    <div className={style.header__local__img}>
                        
                    </div>
                    <div>
                        <p className={style.header__contato__fone}>Matriz - Campo Grande - MS</p>
                        <p className={style.header__contato__email}>Rua Sao Thomas, 930.</p>
                    </div>

                </section>
            </header>

            <Menu/>
        </>
    );
}