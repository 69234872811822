import style from './Message.module.scss';
import classNames from 'classnames';

interface Props {
    message: string | undefined,
    type: number
}

export default function Message(valor: Props) {

    return(
        <div className={
            classNames({
                [style.message]: true,
                [style[`${valor.message ? valor.type === 2 ? 'message__error' : 'message__success' : ''}`]]: true
            })
        }
        >
            {valor.message ? <p>{valor.message} </p> : null}
        </div>
    );
}