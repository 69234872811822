
import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getServicos = async () => {
    return (await http.get('/servicos'));
};

export const getServicoById = async(id: number) => {
    return (await http.get(`/servicos/details/${id}`));
};


export const getServicoByTitulo = async(titulo: string | null) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get(`/servicos/titulo?titulo=${titulo}`, {headers}));
};


export const cadServico = async (data: any) => {    
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.post('/servicos', data, {headers}));
};

export const altServico = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/servicos/${id}`, data, {headers}));
};

export const delServico = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    }; 
    return (await http.delete(`/servicos/${id}`, {headers})).status;
};

export const uploadFoto = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/servicos/upload?id=${id}`, data, {headers}));
};