import Titulo from 'components/Titulo';
import { useState } from 'react';
import { getNoticiaById, getNoticias } from 'services/NoticiaDataService';
import style from './Remover.module.scss';

import { useNavigate, useParams } from 'react-router-dom';
import BotaoExcluir from 'components/Botao/BotaoLink/BotaoExcluir';
import Botao from 'components/Botao';
import { delImagem } from 'services/ImagemDataService';
import Message from 'components/Message';
import Voltar from 'components/Voltar';

export default function Remover() {

    const { id } = useParams();

    const [template] = [{
        id: 0,
        titulo: '',
        subtitulo: '',
        indiceImagens: 0
    }];    

    const templateImagens: number[] | (() => number[]) = [];

    const [listImagens, setListImagens] = useState(templateImagens);

    async function getImagens() {

        const noticias = await getNoticiaById(Number(id));
        
        const imagens: number[] | (() => number[]) = [];

        for (let index = 1; index <= noticias.data.indiceImagens; index++) {

            imagens.push(index);
        }

        setListImagens(imagens);
    }

    useState(() => {

        getImagens();
    });

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(2);

    const navigate = useNavigate();

    async function excluir(index: number) {

        const status = await delImagem(
            Number(id), 
            Number(index),
            {
                'tipo': 'NOTICIA',
                'id': id,
                'index': index
            }
        ).then((sucesso) => {

            setMessage('Imagem removida com sucesso.');
            setTypeMessage(1);
            navigate(`/admin/noticias/imagens/remover/${id}`);
        }).catch((erro) => {

            setMessage('Ocorreu um erro ao remover a imagem.');
            setTypeMessage(2);
        });

    }

    return(
        <section className={style.remover}>
            
            <Titulo
                titulo={'Remover imagens'}
                color={'black'}
            />

            <section className={style.remover__lista}>

                <Message type={typeMessage} message={message} />

                <div className={style.first}>
                    {
                        listImagens.map(index => (

                            <div 
                                className={style.first}
                                key={index}
                            >
                                <div className={style.first__box}>
                                    <img 
                                        key={index}
                                        src={`https://www.grupocappelletto.com//src/assets/fotos/noticias/n${id}-${index}.png`}
                                        className={style.first__second__box__image}
                                        alt={'Imagem'}
                                    />
                                    <Botao 
                                        type="submit" 
                                        onClick={() => excluir(Number(index))}
                                    >
                                        Remover
                                    </Botao>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <Voltar/>
            </section>
        </section>
    );
}