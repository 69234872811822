import style from './Atuacao.module.scss';
import tema from 'styles/Tema.module.scss';
import Botao from 'components/Botao';
import { useEffect, useState } from 'react';
import { altSobre, getSobreByTipo, uploadFoto} from 'services/SobreDataService';
import { useNavigate } from 'react-router-dom';
import { isUserLogged } from 'common/utils/authUtils';
import Voltar from 'components/Voltar';
import Titulo from 'components/Titulo';
import Message from 'components/Message';

export default function Atuacao() {

    const [atuacao, setDados] = useState(
        {
            'id': '',
            'descricao': '',
            'descricaoResumida': ''
        }
    );

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [id, setId] = useState('');
    const [tipo, setTipo] = useState(1);
    const [descricao, setDescricao] = useState('');
    const [descricaoResumida, setDescricaoResumida] = useState('');

    const navigate = useNavigate();
    function verificaAtuacaoLogado() {
        if (isUserLogged() === false) {
            return navigate('/login');
        }
    }

    useEffect(() => {
        verificaAtuacaoLogado();
        getAtuacao();
    },[]);

    async function getAtuacao() {

        setTipo(3);
        const atuacao = await getSobreByTipo(3);

        console.log(atuacao);

        setId(atuacao.data.id);
        setDados(atuacao.data);
        setDescricao(atuacao.data.descricao);
        setDescricaoResumida(atuacao.data.descricaoResumida);
    }

    async function alterar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await altSobre(
            Number(id),
            {
                'descricao': descricao.trim(),
                'tipo': 3,
                'descricaoResumida': descricaoResumida.trim()
            }
        ).then((sucesso) => {

            setMessage('Atuação alterada com sucesso.');
            setTypeMessage(1);

            navigate('/cadastro-realizado/2');
        }).catch((erro) => {
            const message = erro.message;
            setTypeMessage(2);  
        });
    }

    const onFileChangeHandler = async (e: any) => {
        
        if (e[0].size > 1048576) {
            setMessage('O tamanho da imagem não dever ultrapassar o limite máximo de 1mb.');
            setTypeMessage(2);
            return;
        }

        const fotoEmpresa = {
            selectedFile: e[0]
        };
        
        const formData = new FormData();
        
        formData.append('file', fotoEmpresa.selectedFile);

        const response = await uploadFoto(3, formData)
            .then((sucesso) => {
                setMessage('Imagem enviada com sucesso');
            }).catch((erro) => {
                setMessage(erro.message);
                setTypeMessage(2);
            });
    };

    return (
        <form onSubmit={alterar} className={style.atuacao}>
            <Message type={1} message={message} />

            <Titulo titulo={'Página Inicial > Sobre > Atuação'} color={'black'} />

            <section className={style.atuacao__form}>

                <div className={style.atuacao__form__foto}>
                    <img className={style.atuacao__form__foto__imagem} src={`https://www.grupocappelletto.com//src/assets/fotos/sobre/s${tipo}.png`} alt={'Imagem do Atuacao'} />

                    <label className={style.atuacao__form__foto__input__label}>
                        <input 
                            className={style.atuacao__form__foto__input}
                            type="file" 
                            name="foto"
                            accept='image/jpg,image/png'
                            onChange={async (e) => 
                            {
                                await onFileChangeHandler(e.target.files);
                            }}
                        />
                        Enviar imagem 
                    </label>
                </div>

                <div className={tema.boxDefaultHorizontal}>
              
                    <p>
                        <label className={tema.label}>
                        Descrição Resumida
                        </label>
                    </p>

                    <textarea
                        className={tema.inputText}
                        value={descricaoResumida}
                        rows={5}
                        cols={33}
                        required
                        maxLength={1000}
                        placeholder="Descrição resumida de 'Quem Somos'"
                        onChange={(e) => setDescricaoResumida(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Descrição Completa
                        </label>
                    </p>

                    <textarea
                        className={tema.inputText}
                        value={descricao}
                        rows={5}
                        cols={33}
                        required
                        maxLength={3000}
                        placeholder="Descrição completa de 'Quem Somos'"
                        onChange={(e) => setDescricao(e.target.value)}
                    />
                </div>                
            </section>
            <footer className={tema.buttonsBox}>
                <Voltar/>
                <Botao type="submit">
                    Alterar
                </Botao>
            </footer>
        </form>
    );
}