import style from './Trabalhe.module.scss';
import tema from 'styles/Tema.module.scss';
import BotaoTela from 'components/Botao/BotaoTela';
import Titulo from 'components/Titulo';


export default function Trabalhe() {
    
    return(
        <div className={tema.customContainer}>
            <Titulo titulo={'Trabalhe Conosco'} color={'black'} />
            <section className={style.trabalhe}>
                
                <BotaoTela 
                    to={'/admin/trabalhe/alterar'}
                    texto={'Alterar'}
                    icon={'Cadastrar'}
                />
            </section>
        </div>
    );
}