import BotaoTela from 'components/Botao/BotaoTela';
import Titulo from 'components/Titulo';
import style from './Sobre.module.scss';

export default function Sobre() {
    return(
        <section className={style.customContainer}>
            
            <Titulo titulo={'Página Inicial > Sobre'} color={'black'} />

            <div className={style.sobre}>

                <BotaoTela
                    to={'/admin/sobre/quemSomos'}
                    texto={'Quem Somos'}
                    icon={'Empresas'}
                />

                <BotaoTela
                    to={'/admin/sobre/clientes'}
                    texto={'Clientes'}
                    icon={'Clientes'}
                />

                <BotaoTela
                    to={'/admin/sobre/atuacao'}
                    texto={'Atuação'}
                    icon={'Info'}
                />

            </div>

        </section>
    );
}