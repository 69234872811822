import style from './Item.module.scss';
import tema from 'styles/Tema.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
    id: number,
    nome: string,
    sobrenome: string
}

export default function Item(props: Props) {

    const{ id, nome, sobrenome} = props;
    const navigate = useNavigate();

    return (
        <div className={style.item} onClick={() => navigate(`/admin/funcionarios/alterar/${id}`)}>
            <div className={style.item__imagem}>
            </div>
            <div className={style.item__descricao}>
                <div className={tema.tituloLista}>
                    <h2>{nome} {sobrenome}.</h2>
                </div>
            </div>
        </div>
    );
}