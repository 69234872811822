import style from './Cliente.module.scss';
import tema from 'styles/Tema.module.scss';
import BotaoTela from 'components/Botao/BotaoTela';
import Titulo from 'components/Titulo';

export default function Cliente() {

    return(
        <div className={tema.customContainer}>
            <Titulo titulo={'Clientes'} color={'black'} />
            <section className={style.cliente}>
                
                <BotaoTela 
                    to={'/admin/cliente/page/alterar'}
                    texto={'Alterar pág. de clientes'}
                    icon={'Alterar'}
                />

                <BotaoTela 
                    to={'/admin/cliente/cadastrar/'}
                    texto={'Cadastrar cliente'}
                    icon={'Cadastrar'}
                />

                <BotaoTela 
                    to={'/admin/cliente/pesquisar/'}
                    texto={'Pesquisar clientes'}
                    icon={'Pesquisar'}
                />

            </section>
        </div>
    );
}