import DescricaoComponent from 'components/DescricaoComponent';
import Titulo from 'components/Titulo';
import TituloPequeno from 'components/TituloPequeno';
import { useEffect, useState } from 'react';
import { getCrudClientes } from 'services/ClienteCrudDataService';
import { getClientes } from 'services/ClienteDataService';
import style from './Page.module.scss';

export default function Page() {

    const [template] = [{
        id: 0,
        titulo: '',
        subtitulo: '',
        descricao: ''
    }];

    const [templateCrud] = [[{
        id: 0,
        nome: '',
        sobrenome: ''
    }]];

    const [id, setId] = useState();

    const [dataCliente, setDataCliente] = useState(template);
    const [listClientes, setListClientes] = useState(templateCrud);

    useEffect(() => {
        getDataCliente();
        getClientesCrud();
    },[id]);

    async function getDataCliente() {
        const dataCliente = await getClientes();
        setDataCliente(dataCliente.data.content[0]);
    }

    async function getClientesCrud() {
        const listClientes = await getCrudClientes();
        setListClientes(listClientes.data.content);

    }

    return(
        <section className={style.cliente}>
            <Titulo
                titulo={dataCliente.titulo}
                color={'black'}
            />
            <section className={style.cliente__lista}>
                <div className={style.cliente__first__text}>
                    <DescricaoComponent
                        descricao={dataCliente.subtitulo}
                        size={'medium'}
                    />
                </div>
                <div className={style.cliente__first}>
                    {listClientes.map(item => (
                        <div className={style.cliente__first__box} key={item.id}>
                            <img className={style.cliente__first__box__image}
                                src={`https://www.grupocappelletto.com//src/assets/fotos/clienteCrud/c${item.id}.png`}
                                alt={'Empresa'}
                            />
                        </div>
                    ))}
                </div>
            </section>
        </section>
    );
}
