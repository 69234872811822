
import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getServicoPages = async () => {
    return (await http.get('/servicoPages'));
};

export const getServicoPageById = async(id: number) => {
    return (await http.get(`/servicoPages/details/${id}`));
};

export const getServicoPageInicio = async(id: number) => {
    return (await http.get(`/servicoPages/servicoPageInicio/${id}`));
};

export const cadServicoPage = async (data: any) => {    
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.post('/servicoPages', data, {headers}));
};

export const altServicoPage = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/servicoPages/${id}`, data, {headers}));
};

export const delServicoPage = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    }; 
    return (await http.delete(`/servicoPages/${id}`, {headers})).status;
};
