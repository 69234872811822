import classNames from 'classnames';
import style from './DescricaoComponent.module.scss';

interface Props {
    descricao: string,
    color?: string,
    textalign?: string,
    size?: string
}

export default function DescricaoComponent(props: Props) {
    return(
        <>
            <p  
                className={
                    classNames({
                        [style.descricaoComponent]: true,
                        [style[`${props.color === 'white' ? 'descricaoComponent__white' : 'descricaoComponent__dark'}`]]: true,
                        [style[`${props.textalign === 'center' ? 'descricaoComponent__center' : 'descricaoComponent__left'}`]]: true,
                        [style[`${props.size === 'large' 
                            ? 'descricaoComponent__large' 
                            : props.size === 'medium' 
                                ? 'descricaoComponent__medium' 
                                : 'descricaoComponent__common'}`]]: true
                    })
                }
            >
                {props.descricao}
            </p>
        </>
    );
}