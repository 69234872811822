import BotaoTela from 'components/Botao/BotaoTela';
import tema from 'styles/Tema.module.scss';
import style from './Depoimento.module.scss';
import Titulo from 'components/Titulo';

export default function Depoimento() {

    return(
        <section className={tema.customContainer}>

            <Titulo titulo={'Depoimentos'} color={'black'}/>

            <div className={style.depoimento}>

                <BotaoTela
                    to={'/admin/depoimentos/pesquisar'}
                    texto={'Pesquisar'}
                    icon={'Pesquisar'}
                />

                <BotaoTela
                    to={'/admin/depoimentos/cadastrar'}
                    texto={'Cadastrar'}
                    icon={'Cadastrar'}
                />

            </div>
        </section>
    );
}