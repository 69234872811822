import { Link } from 'react-router-dom';
import style from './BotaoLink.module.scss';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { FcSearch, FcContacts } from 'react-icons/fc';
import empresaBT from 'assets/img/empresas.png';
import findBT from 'assets/img/findBg.png';
import personBT from 'assets/img/person2.png';
import vagaBT from 'assets/img/hands.png';
import classNames from 'classnames';

interface Props {
    to: string,
    texto: string,
    icon?: string,
    styleAlt?: string,
    styleAlt__icon?: string
}

export default function BotaoLink(dados: Props) {
    return(
        <Link
            className={
                dados.styleAlt === '' || dados.styleAlt === undefined ? 
                    dados.texto === 'Excluir' ? 
                        classNames({
                            [style.botaoLink]:true,
                            [style.botaoLink__excluir]:true
                        }): style.botaoLink : 
                    dados.styleAlt
            }
            to={dados.to}
        >
            <div className={style.botaoLink__box}>
                {dados.icon === 'BsChevronLeft' ?
                    <div className={style.botaoLink__icon}>
                        <BsChevronLeft/> 
                    </div> : ''
                }
                <div className={style.botaoLink__cadastrar}>
                    {dados.texto} {' '}
                </div>
                {dados.icon === 'BsChevronRight' ?    
                    <div className={style.botaoLink__icon}>
                        <BsChevronRight/> 
                    </div>
                    : dados.icon === 'Usuario' ?    
                        <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoLink__icon : dados.styleAlt__icon}>
                            <img className={style.footer__img} src={personBT} alt={'Vagas'} />
                        </div>
                        : dados.icon === 'Empresas' ?
                            <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoLink__icon : dados.styleAlt__icon}>
                                <img className={style.footer__img} src={empresaBT} alt={'Empresas'} /> 
                            </div>
                            : dados.icon === 'Servicos' ?
                                <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoLink__icon : dados.styleAlt__icon}>
                                    <img className={style.footer__img} src={findBT} alt={'Serviços'} /> 
                                </div>
                                : dados.icon === 'Curriculos' ?
                                    <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoLink__icon : dados.styleAlt__icon}>
                                    </div>
                                    : dados.icon === 'Vagas' ?
                                        <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoLink__icon : dados.styleAlt__icon}>
                                            <img className={style.footer__img} src={vagaBT} alt={'Vagas'} />
                                        </div>
                                        : dados.icon === 'Pesquisar' ?
                                            <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoLink__icon : dados.styleAlt__icon}>
                                                <FcSearch size={32}/> 
                                            </div>
                                            : dados.icon === 'Contato' ?
                                                <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoLink__icon : dados.styleAlt__icon}>
                                                    <FcContacts size={32}/> 
                                                </div>
                                                :''
                }
            </div>
        </Link>
    );
}