import style from './Alterar.module.scss';
import tema from 'styles/Tema.module.scss';
import Botao from 'components/Botao';
import { useEffect, useState } from 'react';
import { altDado, getDadoById} from 'services/DadoDataService';
import { useNavigate, useParams } from 'react-router-dom';
import { isUserLogged } from 'common/utils/authUtils';
import Voltar from 'components/Voltar';
import Titulo from 'components/Titulo';
import Message from 'components/Message';
import { BsBoxSeam, BsDiagram3, BsFillPeopleFill, BsFillPersonLinesFill } from 'react-icons/bs';

export default function Dado() {

    const { id } = useParams();

    const [dado, setDados] = useState(
        {
            'id': '',
            'descricao': '',
            'quantidade': '',
            'tipo': ''
        }
    );

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [tipo, setTipo] = useState(1);
    const [descricao, setDescricao] = useState('');
    const [quantidade, setQuantidade] = useState(1);

    const navigate = useNavigate();
    function verificaDadoLogado() {
        if (isUserLogged() === false) {
            return navigate('/login');
        }
    }

    useEffect(() => {
        verificaDadoLogado();
        getDado();
    },[]);

    async function getDado() {

        const dado = await getDadoById(Number(id));

        console.log(dado);

        setDados(dado.data);
        setDescricao(dado.data.descricao);
        setTipo(dado.data.tipo);
        setQuantidade(dado.data.quantidade);
    }

    async function alterar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await altDado(
            Number(id),
            {
                'descricao': descricao.trim(),
                'tipo': tipo,
                'quantidade': quantidade
            }
        ).then((sucesso) => {

            setMessage('Dado alterado com sucesso.');
            setTypeMessage(1);

            navigate('/cadastro-realizado/2');
        }).catch((erro) => {
            const message = erro.message;
            setTypeMessage(2);  
        });
    }

    return (
        <form onSubmit={alterar} className={style.alterar}>
            <Message type={1} message={message} />

            <Titulo titulo={'Página Inicial > Dados > Alterar'} color={'black'} />

            <section className={style.alterar__form}>

                <div className={tema.boxDefault}>
              
                    <p>
                        <label className={tema.label}>
                        Descrição
                        </label>
                        <input
                            className={tema.input}
                            type="text"
                            value={descricao}
                            maxLength={100}
                            required
                            placeholder="Descrição"
                            onChange={(e) => setDescricao(e.target.value)}
                        />
                    </p>

                    <p>
                        <label className={tema.label}>
                        Quantidade
                        </label>
                        <input
                            className={tema.input}
                            type="number"
                            value={quantidade}
                            maxLength={100}
                            required
                            placeholder="Quantidade"
                            onChange={(e) => setQuantidade(Number(e.target.value))}
                        />
                    </p>

                    <p>
                        <label className={tema.label}>
                        Ícone
                        </label>
                    </p>
                    <p>
                        <select 
                            className={tema.selCombo} 
                            value={tipo} 
                            onChange={(e) => setTipo(Number(e.target.value))}
                        >
                            
                            <option key='1' value='1'>
                                 Pessoa 
                            </option>
                            <option key='2' value='2'>
                                Carga
                            </option>
                            <option key='4' value='4'>
                                Rede
                            </option>
                            <option key='3' value='3'>
                                Pessoas
                            </option>
                        </select>

                        {tipo === 1 ? 
                            <BsFillPeopleFill size={26} className={style.icon}/>
                            : tipo === 2 ?
                                <BsBoxSeam size={26} className={style.icon}/> 
                                : tipo === 3 ?
                                    <BsFillPersonLinesFill size={26} className={style.icon}/> 
                                    : tipo === 4 ?
                                        <BsDiagram3 size={26} className={style.icon}/>
                                        : ''
                        }
                    </p>
                </div>                
            </section>
            <br/>
            <footer className={tema.buttonsBox}>
                <Voltar/>
                <Botao type="submit">
                    Alterar
                </Botao>
            </footer>
        </form>
    );
}