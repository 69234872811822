import Titulo from 'components/Titulo';
import { useEffect, useState } from 'react';
import { getTrabalheDetails } from 'services/TrabalheDataService';
import style from './Page.module.scss';
import tema from '../../../styles/Tema.module.scss';
import Botao from 'components/Botao';
import DescricaoComponent from 'components/DescricaoComponent';
import { enviarEmail } from 'services/EmailDataService';
import Message from 'components/Message';

export default function Page() {

    const [template] = [{
        'remetente': '',
        'corpo': ''
    }];    

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [descricao, setDescricao] = useState('');

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);

    async function getDataTrabalhe() {
        const dataTrabalhe = await getTrabalheDetails();
        dataTrabalhe.data.id = 1;
        setDescricao(dataTrabalhe.data.descricao);
    }

    useEffect(() => {
        getDataTrabalhe();
    });

    async function prepararEEnviarEmail(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await enviarEmail(
            {
                'remetente': {
                    'nome': nome,
                    'email': email,
                    'telefone': telefone
                },
                'corpo': mensagem
            }
        ).then((sucesso) => {

            setMessage('E-mail enviado com sucesso.');
            setTypeMessage(1);

        }).catch((erro) => {
            const message = erro.message;
            setTypeMessage(2);  
        });
    }

    return(
        <section className={style.trabalhe}>
            <Titulo
                titulo={'Trabalhe Conosco'} 
                color={'black'}
            />
            <Message type={1} message={message} />
            <section className={style.first}>
                <form onSubmit={prepararEEnviarEmail}>
                    <div className={style.first__box}>
                        <p>  
                            <label className={tema.label}>
                            Nome
                            </label>
                        </p>
                        <p>    
                            <input
                                className={tema.input}
                                type="text"
                                value={nome}
                                maxLength={70}
                                required
                                placeholder="Digite seu nome"
                                onChange={(e) => setNome(e.target.value)}
                            />
                        </p>
                        <p>  
                            <label className={tema.label}>
                            Telefone
                            </label>
                        </p>
                        <p>    
                            <input
                                className={tema.input}
                                type="text"
                                value={telefone}
                                maxLength={70}
                                required
                                placeholder="Digite seu nome"
                                onChange={(e) => setTelefone(e.target.value)}
                            />
                        </p>
                        <p>  
                            <label className={tema.label}>
                            E-mail
                            </label>
                        </p>
                        <p>    
                            <input
                                className={tema.input}
                                type="text"
                                value={email}
                                maxLength={100}
                                required
                                placeholder="Digite seu e-mail"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </p>
                        <p>
                            <label className={tema.label}>
                            Mensagem
                            </label>
                            <textarea
                                className={tema.inputText}
                                value={mensagem}
                                rows={5}
                                cols={33}
                                required
                                placeholder="Digite sua mensagem"
                                onChange={(e) => setMensagem(e.target.value)}
                            />
                        </p>   
                        <Botao 
                            type="submit"
                            formato={'blue'}
                        >
                            Enviar
                        </Botao>
                    </div>
                    <div className={style.first__box}>

                        <div className={style.first__box__imagem}>
                            <img 
                                className={style.first__box__imagem__propriedades} 
                                src={'https://www.grupocappelletto.com//src/assets/fotos/trabalhe/t1.png'} 
                                alt={'Imagem do institucional'} 
                            />
                        </div>

                        <DescricaoComponent
                            size={'large'}
                            descricao={descricao}
                        />
                    </div>                
                </form>        
            </section>
        </section>
    );
}