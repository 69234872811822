import { useNavigate } from 'react-router-dom';
import style from './VoltarTela.module.scss';

export default function VoltarTela(){

    const navigate = useNavigate();

    return(
        <div className={style.voltar}>
            <div className={style.voltar__box}>
                <button className={style.voltar__confirmar} onClick={() => navigate(-1)}>
                    {'Voltar'}
                </button>
            </div>
        </div>
    );
}