import style from './Item.module.scss';
import tema from 'styles/Tema.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
    id: number,
    titulo: string
}

export default function Item(props: Props) {

    const{ id, titulo} = props;
    const navigate = useNavigate();

    return (
        <div className={style.item} onClick={() => navigate(`/admin/noticias/alterar/${id}`)}>
            <div className={style.item__imagem}>
            </div>
            <div className={style.item__descricao}>
                <div className={tema.tituloLista}>
                    <h2>{titulo}.</h2>
                </div>
            </div>
        </div>
    );
}