import style from './Alterar.module.scss';
import tema from 'styles/Tema.module.scss';
import Botao from 'components/Botao';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isUserLogged } from 'common/utils/authUtils';
import Voltar from 'components/Voltar';
import Titulo from 'components/Titulo';
import Message from 'components/Message';
import { altServicoPage, getServicoPageById } from 'services/ServicoPageDataService';
import Lista from 'pages/Servico/Lista';
import BotaoTela from 'components/Botao/BotaoTela';

export default function Alterar() {

    const [contato, setDados] = useState(
        {
            'id': '',
            'subtitulo': ''
        }
    );

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [id, setId] = useState(1);
    const [subtitulo, setSubtitulo] = useState('');

    const navigate = useNavigate();
    function verificaContatoLogado() {
        if (isUserLogged() === false) {
            return navigate('/login');
        }
    }

    useEffect(() => {
        verificaContatoLogado();
        getContato();
    },[]);

    async function getContato() {
        const contato = await getServicoPageById(1);
        setDados(contato.data);
        setSubtitulo(contato.data.subtitulo);
    }


    async function alterar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await altServicoPage(
            Number(id),
            {
                'subtitulo': subtitulo
            }
        ).then((sucesso) => {
            setMessage('Dados alterados com sucesso.');
            setTypeMessage(1);
            navigate('/cadastro-realizado/2');
        }).catch((erro) => {
            const message = erro.message;
            setTypeMessage(2);  
        });
    }

    return (
        <form onSubmit={alterar} className={style.alterar}>

            <Message type={1} message={message} />

            <Titulo titulo={'Página Inicial > Serviços'} color={'black'} />

            <div className={tema.boxDefault}>
                <p>  
                    <label className={tema.label}>
                        Subtítulo
                    </label>
                </p>
                <p>
                    <textarea
                        className={tema.inputText}
                        value={subtitulo}
                        required
                        maxLength={200}
                        placeholder="Subtítulo da sessão de serviços"
                        onChange={(e) => setSubtitulo(e.target.value)}
                    />
                </p>

            </div>

            <BotaoTela
                to={'/admin/servicos/pesquisar'}
                texto={'Listar serviços'}
                icon={'Pesquisar'}
            />

            <footer className={tema.footer}>
                <Voltar/>
                <Botao type="submit">
                    Alterar
                </Botao>
            </footer>

        </form>
    );
}