import style from './Menu.module.scss';
import { Container, Nav, Navbar } from 'react-bootstrap';

export default function Menu() {

    return (
        <Navbar className={style.menu} expand='lg' bg='primary' variant='dark' >
            <Container>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav>
                        <Nav.Link href='/inicio' className={style.menu__link__flex}>Início</Nav.Link>
                        <Nav.Link href='/noticias' className={style.menu__link__flex}>Notícias</Nav.Link>
                        <Nav.Link href='/institucional' className={style.menu__link__flex}>Institucional</Nav.Link>
                        <Nav.Link href='/ondeEstamos' className={style.menu__link__flex}>Onde Estamos</Nav.Link>
                        <Nav.Link href='/servico' className={style.menu__link__flex}>Serviços</Nav.Link>
                        <Nav.Link href='/contato' className={style.menu__link__flex}>Contatos</Nav.Link>
                        <Nav.Link href='/trabalhe' className={style.menu__link__flex}>Trabalhe Conosco</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}