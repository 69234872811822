import Item from './Item';
import style from './Lista.module.scss';
import { useEffect, useState } from 'react';
import { getNoticias } from 'services/NoticiaDataService';


interface Props {
    busca: string,
    filtro: number | null,
    ordenador: string
}

export default function Lista(props:Props) {

    const template = 
    [
        {
            'id': 0,
            'titulo': ''
        }
    ];

    const [listaInicial, setListaInicial] = useState(template);

    const [listaFinal, setListaFinal] = useState(template);

    const {busca, filtro, ordenador} = props;

    function testaBusca(title: string) {
        const regex = new RegExp(busca, 'i');
        return regex.test(title);
    }

    function testaFiltro(id: number) {
        if (filtro !== null) {
            return filtro === id;
        }
        return true;
    }

    useEffect(() => {   
        getListaNoticias();
        setListaFinal(listaFinal.length === 1 ? listaInicial : listaInicial.filter(item => testaBusca(item.titulo) && testaFiltro(item.id)));
    },[busca, filtro, ordenador]);


    async function getListaNoticias() {
        const resposta = await getNoticias();
        setListaInicial(resposta.data.content);
        if (busca === '') {
            setListaFinal(resposta.data.content);
        }
    }

    return (
        <section className={style.itens}>
            {listaFinal.map(item => (
                <Item key={item.id} {...item} />
            ))}
        </section>
    );
}