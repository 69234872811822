import { Link } from 'react-router-dom';
import style from './BotaoTela.module.scss';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { FcReading, FcNews, FcOrganization, FcConferenceCall, FcPlus, FcTemplate, FcOrgUnit, FcSearch, FcContacts, FcDataConfiguration } from 'react-icons/fc';
import personBT from 'assets/img/person2.png';
import vagaBT from 'assets/img/hands.png';

interface Props {
    to: string,
    texto: string,
    icon?: string,
    styleAlt?: string,
    styleAlt__icon?: string
}

export default function BotaoTela(dados: Props) {
    return(
        <div className={style.botaoTela__box}>
            <Link
                className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela : dados.styleAlt}
                to={dados.to}
            >   
                <div className={style.botaoTela__texto}>
                    {dados.texto} {' '}
                </div>
                <div className={style.botaoTela__iconBox}>
                    {dados.icon === undefined ?
                        <div className={style.botaoTela__icon}>
                            <BsChevronRight/> 
                        </div> : ''
                    }
                    {dados.icon === 'Voltar' ?
                        <div className={style.botaoTela__icon}>
                            <BsChevronLeft/> 
                        </div> : ''
                    }
                    {dados.icon === 'Listar' ?    
                        <div className={style.botaoTela__icon}>
                            <FcTemplate/> 
                        </div>
                        : dados.icon === 'Usuario' ?    
                            <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                <img className={style.botaoTela__img} src={personBT} alt={'Vagas'} />
                            </div>
                            : dados.icon === 'Empresas' ?
                                <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                    <FcOrganization size={32}/> 
                                </div>
                                : dados.icon === 'Servicos' ?
                                    <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                        <FcOrgUnit size={32}/>
                                    </div>
                                    : dados.icon === 'Curriculos' ?
                                        <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                        </div>
                                        : dados.icon === 'Vagas' ?
                                            <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                                <img className={style.botaoTela__img} src={vagaBT} alt={'Vagas'} />
                                            </div>
                                            : dados.icon === 'Pesquisar' || dados.icon === 'Filtrar' ?
                                                <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                                    <FcSearch size={32}/> 
                                                </div>
                                                : dados.icon === 'Contato' ?
                                                    <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                                        <FcContacts size={32}/> 
                                                    </div>
                                                    : dados.icon === 'Categorias' ?
                                                        <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                                            <FcOrgUnit size={32}/> 
                                                        </div>
                                                        : dados.texto === 'Cadastrar' || dados.icon === 'Cadastrar' ?
                                                            <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                                                <FcPlus size={32}/> 
                                                            </div>
                                                            : dados.texto === 'Alterar' || dados.icon === 'Alterar' ?
                                                                <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                                                    <FcDataConfiguration size={32}/> 
                                                                </div>
                                                                : dados.texto === 'Clientes' || dados.icon === 'Clientes' ?
                                                                    <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                                                        <FcConferenceCall size={32}/> 
                                                                    </div>
                                                                    : dados.texto === 'Informaçoes' || dados.icon === 'Info' ?
                                                                        <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                                                            <FcReading size={32}/> 
                                                                        </div>
                                                                        : dados.texto === 'Notícias' || dados.icon === 'Notícias' ?
                                                                            <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoTela__icon : dados.styleAlt__icon}>
                                                                                <FcNews size={32}/> 
                                                                            </div>
                                                                            :''
                    }
                </div>
            </Link>
        </div>
    );
}