import classNames from 'classnames';
import React from 'react';
import style from './Botao.module.scss';

interface Props {
    type?: 'button' | 'submit' | 'reset' | undefined,
    onClick?: () => void,
    children?: React.ReactNode,
    formato?: string
}

export default function Botao({ onClick, type, children, formato}: Props) {
    return(
        <button 
            onClick={onClick} 
            type={type} 
            className={
                classNames({
                    [style.botao]:true,
                    [style.botao__default]:formato === '' || formato === undefined,
                    [style.botao__blue]:formato === 'blue'
                })
            }
        >
            {children}
        </button>
    );
}