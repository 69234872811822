import style from './Cadastrar.module.scss';
import tema from 'styles/Tema.module.scss';
import { useEffect, useState } from 'react';
import { cadDepoimento } from 'services/DepoimentoDataService';
import { useNavigate } from 'react-router-dom';
import Titulo from 'components/Titulo';
import Voltar from 'components/Voltar';
import Botao from 'components/Botao';

export default function Cadastrar() {

    const navigate = useNavigate();


    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [depoimento, setDepoimento] = useState('');

    async function cadastrar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await cadDepoimento({
            'nome': nome,
            'descricao': descricao,
            'depoimento': depoimento.toLowerCase().trim()
        }
        ).then((sucesso) => {
            clearForm();
            setTypeMessage(1);
            navigate(`/admin/depoimentos/alterar/${'a' + sucesso.data.id}`);
        }).catch((erro) => {
            const message = erro.message;
            setMessage(message);
            setTypeMessage(2);  
        });
    }

    function clearForm() {
        setNome('');
        setDescricao('');
        setDepoimento('');
    }

    return (
        <section >
            <Titulo titulo={'Cadastrar Depoimento'} color={'black'}/>
            <form onSubmit={cadastrar} className={style.cadastrar}>
                <div className={tema.boxDefault}>
                    <p>
                        <label className={tema.label}>
                        Nome da pessoa
                        </label>
                    </p>
                    <p>
                        <input
                            className={tema.input}
                            type="text"
                            value={nome}
                            maxLength={50}
                            required
                            placeholder="Nome da pessoa"
                            onChange={(e) => setNome(e.target.value)}
                        />
                    </p>

                    <p>
                        <label className={tema.label}>
                        Descrição
                        </label>
                    </p>

                    <p>
                        <input
                            className={tema.input}
                            type="text"
                            value={descricao}
                            required
                            maxLength={100}
                            placeholder="Cargo, título ou descrição da pessoa"
                            onChange={(e) => setDescricao(e.target.value)}
                        />
                    </p>

                    <p>
                        <label className={tema.label}>
                        Depoimento
                        </label>
                    </p>

                    <textarea
                        className={tema.inputText}
                        value={depoimento}
                        rows={5}
                        cols={33}
                        required
                        placeholder="Depoimento"
                        onChange={(e) => setDepoimento(e.target.value)}
                    />

                </div>
                <footer className={tema.footer}>     
                    <Voltar/>
                    <Botao type="submit">
                        Cadastrar
                    </Botao>
                </footer>
            </form>
        </section>
    );
}