import style from './HomePage.module.scss';
import { useState } from 'react';
import { getGuestId, isUserLogged } from 'common/utils/authUtils';
import Sobre from '../Sobre/HomePage';
import Servico from './Servico';
import Dados from './Dados';
import Clientes from './Clientes';
import NoticiaInicio from './NoticiaInicio';
import { getNoticias } from 'services/NoticiaDataService';
import { getServicoPageInicio } from 'services/ServicoPageDataService';
import { getSobres } from 'services/SobreDataService';
import Apresentacao from 'pages/Apresentacao';
import { getDados } from 'services/DadoDataService';
import { getCrudClientes } from 'services/ClienteCrudDataService';
import { getDepoimentos } from 'services/DepoimentoDataService';

export default function HomePage() {

    const [templateList] = [[{
        id: 0,
        titulo: '',
        subtitulo: '',
        descricaoResumida: '',
        exibirSite: '',
        indiceImagens: 0
    }]];    

    const [templateSobre] = [[{
        id: 0,
        descricao: '',
        descricaoResumida: ''
    }]];    
    
    const [templateDado] = [[{
        id: 0,
        descricao: '',
        quantidade: 0,
        tipo: 0
    }]];    

    const [templateCliente] = [[{
        id: 0
    }]];    

    const [templateDepoimento] = [[{
        id: 0,
        nome: '',
        descricao: '',
        depoimento: ''
    }]];    

    const template = {
        subtitulo: '',
        listServicoCrud: templateList
    };

    const [listNoticias, setListNoticias] = useState(templateList);
    const [listSobre, setListSobre] = useState(templateSobre);
    const [listDado, setListDado] = useState(templateDado);
    const [listCliente, setListCliente] = useState(templateCliente);
    const [listDepoimentos, setLitsDepoimentos] = useState(templateDepoimento);
    const [servicoPages, setServicoPages] = useState(template);

    async function getListNoticias() {
        const listNoticias = await getNoticias();
        setListNoticias(listNoticias.data.content);
    }

    async function getListSobre() {
        const listSobre = await getSobres();
        setListSobre(listSobre.data.content);
    }

    async function getListDado() {
        const listDado = await getDados();
        setListDado(listDado.data.content);
    }

    async function getListServicoPages() {
        const servicoPages = await getServicoPageInicio(1);
        setServicoPages(servicoPages.data);
    }

    async function getListCliente() {

        const listCliente = await getCrudClientes();
        setListCliente(listCliente.data.content);
    }

    async function getListaDepoimentos() {

        const listDepoimentos = await getDepoimentos();
        setLitsDepoimentos(listDepoimentos.data.content);
    }

    useState( () => {
        if (!isUserLogged()) {
            localStorage.setItem('userMail', getGuestId());
        }
        getListNoticias();
        getListServicoPages();
        getListSobre();
        getListDado();
        getListCliente();
        getListaDepoimentos();
    });

    return (
        <section className={style.inicio}>
            
            <div className={style.inicio__video}>
                <video className={style.inicio__video__play}
                    autoPlay={true}
                    muted={true}
                    playsInline={true}
                    loop={true}
                >
                    <source src={require('../../assets/video/truck.mp4')} type='video/mp4' />
                </video>
                <div className={style.inicio__video__text}>
                    <p className={style.inicio__video__text__subtitulo}>Bem vindo ao</p>
                    <h1 className={style.inicio__video__text__titulo}>Grupo Cappelletto</h1>
                </div>
                
            </div>

            <NoticiaInicio listNoticias={listNoticias}/>

            <Apresentacao/>

            <Servico 
                subtitulo={servicoPages.subtitulo} 
                listServicos={servicoPages.listServicoCrud}
            />

            <Sobre 
                titulo={'Garantir a excelência está em nosso sangue'} 
                subtitulo={'Focados em atender as mais diversas necessidades logísticas do transporte com qualidade, compromisso e respeito.'} 
                listSobre={listSobre}
            />

            <Dados
                listDados={listDado}
            />

            <Clientes listClientes={listCliente} listDepoimentos={listDepoimentos}/>

        </section>
    );
}