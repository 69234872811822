import style from './Login.module.scss';
import tema from 'styles/Tema.module.scss';
import { useEffect, useState } from 'react';
import logo from 'assets/img/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { getLogin } from 'services/LoginDataService';
import { getUsuarioTermosDeUso } from 'services/UsuarioDataService';
import classNames from 'classnames';
import { setExodus } from 'common/utils/authUtils';
import MessageAbsolute from 'components/Message/MessageAbsolute';

export default function Login(){

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);

    useEffect(() => {
        localStorage.clear();
    });

    async function logar(evento: React.FormEvent<HTMLFormElement>) {
        
        evento.preventDefault();

        const response = await getLogin(
            {
                'email': email.toLowerCase().trim(),
                'senha': senha.trim()
            }
        ).then(async (response) => {
            clearForm();

            const responseData = response.data;
            
            const userAuth = {
                'email': email.toLowerCase().trim(),
                'token': responseData.token
            };

            const day = new Date().getDay();
            const responseUsuario = await getUsuarioTermosDeUso(email);
            const userDados = responseUsuario.data;

            localStorage.setItem('cappellettoGrupo', 'a' + (Number(userDados.id) + 300));
            localStorage.setItem('userMail', email);

            localStorage.setItem('cappellettoGrupoLoad', day.toString());

            setExodus(userAuth.token);

            return navigate('/admin/painel');
        }).catch((erro: any) => {

            setMessage('Dados de login inválidos.');
            setTypeMessage(2);
        });
    }

    function clearForm() {
        setEmail('');
        setSenha('');
    }

    return (
        <form className={style.login} onSubmit={logar}>
            <MessageAbsolute message={message} type={typeMessage} />
            <img 
                className={style.login__img}
                src={logo} 
                alt={'Cappelletto Services'}
            />
            <h3 className={style.login__titulo}>Services</h3>
            <section className={tema.boxDefault}>
                <input
                    autoFocus
                    className={style.login__input}
                    type="text"
                    value={email}
                    required
                    placeholder="Digite seu e-mail"
                    onChange={(e) => setEmail(e.target.value)}
                />

                <input
                    className={style.login__input}
                    type="password"
                    value={senha}
                    required
                    placeholder="Digite sua senha"
                    onChange={(e) => setSenha(e.target.value)}
                />
                <button 
                    type="submit" 
                    className={style.login__botao}
                >
                    Entrar
                </button>

                <Link to={'/login/recuperar-senha'} 
                    className={classNames({
                        [style['login__cadastrar']]: true,
                        [style['login__cadastrar__red']]: true
                    })} 
                >
                    Esqueci a senha
                </Link>

            </section>
        </form>
    );
}