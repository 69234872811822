import { Outlet } from 'react-router-dom';
import Menu from './Menu';
import style from './AdminOutlet.module.scss';
import Footer from './Footer';

export default function AdminOutlet() {
    return (
        <section className={style.adminOutlet}>
            <Menu/>
            <Outlet/>
            <Footer/>
        </section>
    );
}