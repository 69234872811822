import 'bootstrap/dist/css/bootstrap.min.css';

interface Props {
    listClientes: {
        id: number
    }[],
    listDepoimentos: {
        id: number,
        nome: string,
        descricao: string,
        depoimento: string
    }[];
}

export default function Clientes(props: Props) {

    function getClienteId(props: Props, index: number) {

        return props.listClientes !== undefined && props.listClientes[index] !== undefined ? props.listClientes[index].id : 1;
    }   

    return(
        <section>

        </section>
    );
}