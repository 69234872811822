
import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getInstitucionals = async () => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get('/institucional', {headers}));
};

export const getInstitucionalDetails = async() => {
    return (await http.get('/institucional/details'));
};


export const getInstitucionalByTitulo = async(titulo: string | null) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get(`/institucional/titulo?titulo=${titulo}`, {headers}));
};


export const cadInstitucional = async (data: any) => {    
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.post('/institucional', data, {headers}));
};

export const altInstitucional = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/institucional/${id}`, data, {headers}));
};

export const delInstitucional = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    }; 
    return (await http.delete(`/institucional/${id}`, {headers})).status;
};

export const uploadFoto = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/institucional/upload?id=${id}`, data, {headers}));
};