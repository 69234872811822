import { Outlet, useNavigate } from 'react-router-dom';
import tema from 'styles/Tema.module.scss';
import { RecoilRoot } from 'recoil';
import Header from 'components/Header';
import { useEffect } from 'react';
import { isLoadBroken } from 'common/utils/authUtils';
import Footer from 'components/Footer';

export default function DefaultPage() {

    const navigate = useNavigate();

    useEffect(() => {
        if (isLoadBroken()) {
            return navigate('/login');
        }
    },[]);

    return (
        <RecoilRoot>
            <div className={tema.container}>
                <Header/>
                <Outlet/>
                <Footer/>
            </div>
        </RecoilRoot>
    );
}