import DescricaoComponent from 'components/DescricaoComponent';
import Subtitulo from 'components/Subtitulo';
import Titulo from 'components/Titulo';
import { useEffect, useState } from 'react';
import { getInstitucionalDetails } from 'services/InstitucionalDataService';
import style from './Page.module.scss';
import parse from 'html-react-parser';

export default function Page() {

    const [template] = [{
        id: 0,
        titulo: '',
        subtitulo: '',
        descricao: ''
    }];    

    const [id, setId] = useState();

    const [dataInstitucional, setDataInstitucional] = useState(template);

    async function getDataInstitucional() {
        const dataInstitucional = await getInstitucionalDetails();
        dataInstitucional.data.id = 1;
        setDataInstitucional(dataInstitucional.data);
    }

    useEffect(() => {
        getDataInstitucional();
    },[id]);

    return(
        <section className={style.institucional}>
            <div className={style.institucional__image}
            />
            <Titulo
                titulo={dataInstitucional.titulo} 
                color={'black'}
            />
            <Subtitulo
                titulo={dataInstitucional.subtitulo} 
            />
            <section className={style.institucional__lista}>
                <div className={style.first}>
                    <div className={style.first__box}>
                        {parse(dataInstitucional.descricao)}
                    </div>
                </div>
            </section>
        </section>
    );
}