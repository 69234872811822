
import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getFuncionarios = async () => {

    return (await http.get('/funcionarios'));
};

export const getFuncionarioById = async(id: number) => {
    return (await http.get(`/funcionarios/details/${id}`));
};


export const getFuncionarioByTitulo = async(titulo: string | null) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get(`/funcionarios/titulo?titulo=${titulo}`, {headers}));
};


export const cadFuncionario = async (data: any) => {    
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.post('/funcionarios', data, {headers}));
};

export const altFuncionario = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/funcionarios/${id}`, data, {headers}));
};

export const delFuncionario = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    }; 
    return (await http.delete(`/funcionarios/${id}`, {headers})).status;
};

export const uploadFoto = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/funcionarios/upload?id=${id}`, data, {headers}));
};