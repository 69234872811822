import BotaoLink from 'components/Botao/BotaoLink';
import DescricaoComponent from 'components/DescricaoComponent';
import Subtitulo from 'components/Subtitulo';
import Titulo from 'components/Titulo';
import TituloPequeno from 'components/TituloPequeno';
import style from './Sobre.module.scss';

interface Props {
    titulo: string,
    subtitulo: string,
    listSobre: {
        id: number,
        descricao: string,
        descricaoResumida: string
    }[];
}

export default function Sobre(props: Props) {
    return(
        <section className={style.box}>
            <Titulo titulo={props.titulo} color={'black'}/>
            <Subtitulo titulo={props.subtitulo}/>

            <section className={style.sobre}>
                <div className={style.first}>
                    <div className={style.first__box}>
                        <img className={style.first__box__image}
                            src={`https://www.grupocappelletto.com//src/assets/fotos/sobre/s${props.listSobre[0] !== undefined ? props.listSobre[0].id : 1}.png`}
                            alt={'Empresa'}
                        />
                        <TituloPequeno titulo={'Quem somos'} to={'/quemSomos'}/>
                        <DescricaoComponent 
                            descricao={props.listSobre[0] !== undefined ? props.listSobre[0].descricaoResumida : ''}
                        />

                    </div>
                    <div className={style.first__box}>
                        <img className={style.first__box__image}
                            src={`https://www.grupocappelletto.com//src/assets/fotos/sobre/s${props.listSobre[1] !== undefined ? props.listSobre[1].id : 2}.png`}
                            alt={'Serviços'}
                        />
                        <TituloPequeno titulo={'Clientes'}/>
                        <DescricaoComponent
                            descricao={props.listSobre[1] !== undefined ? props.listSobre[1].descricao : ''}
                        />

                    </div>
                    <div className={style.first__box}>
                        <img className={style.first__box__image}
                            src={`https://www.grupocappelletto.com//src/assets/fotos/sobre/s${props.listSobre[2] !== undefined ? props.listSobre[2].id : 3}.png`}
                            alt={'Clientes'}
                        />
                        <TituloPequeno titulo={'Atuação'} to={'/atuacao'}/>
                        <DescricaoComponent
                            descricao={props.listSobre[2] !== undefined ? props.listSobre[2].descricaoResumida : ''}
                        />

                    </div>
                </div>
            </section>
        </section>
    );
}