import classNames from 'classnames';
import {BsTruck, BsBoxSeam, BsWrench, BsFillPersonLinesFill, BsFillPeopleFill, BsDiagram3, BsFillTelephoneFill, BsPinMap} from 'react-icons/bs';
import { Link } from 'react-router-dom';
import style from './TituloPequeno.module.scss';

interface Props {
    titulo: string,
    color?: string,
    icon?: string,
    top?: string,
    bottom?: string,
    align?: string,
    to?: string,
    reload?: boolean
}

export default function TituloPequeno(props: Props) {
    
    return(
        <>
            <Link 
                to={props.to !== undefined ? props.to : ''} 
                reloadDocument={props.reload ? true : false}
                className={classNames({
                    [style.tituloPequeno]: true,
                    [style[`${props.top === 'min' ? 'tituloPequeno__mintop' : 'tituloPequeno__midtop'}`]]: true,
                    [style[`${props.bottom === 'max' ? 'tituloPequeno__maxbottom' : 'tituloPequeno__middlebottom'}`]]: true,
                    [style[`${props.align === 'left' ? 'tituloPequeno__nomarginleft' : props.align == 'normal' ? 'tituloPequeno__nomarginleft' : 'tituloPequeno__aligncenter'}`]]: true,
                    [style[`${props.color === 'white' ? 'tituloPequeno__white' : 'tituloPequeno__dark'}`]]: true
                })}
            >
                {props.icon === 'truck' ? 
                    <BsTruck size={40}/> 
                    : props.icon === 'box' ? 
                        <BsBoxSeam size={40}/> 
                        : props.icon === 'oficina' ? <BsWrench size={40}/> 
                            : props.icon === 'pessoa' ? <BsFillPersonLinesFill size={40}/> 
                                : props.icon === 'pessoas' ? <BsFillPeopleFill size={40}/> 
                                    : props.icon === 'grid' ? <BsDiagram3 size={40}/> 
                                        : props.icon === 'fone' ? <BsFillTelephoneFill size={32}/> 
                                            : props.icon === 'local' ? <BsPinMap size={32}/> 
                                                : ''
                }
                {props.titulo}
            </Link>
        </>
    );
}