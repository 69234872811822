
import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';
import { httpMultiPart } from 'http-multipart';

export const getNoticias = async () => {
    return (await http.get('/noticias'));
};

export const getNoticiaById = async(id: number) => {
    return (await http.get(`/noticias/details/${id}`));
};


export const getNoticiaByTitulo = async(titulo: string | null) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get(`/noticias/titulo?titulo=${titulo}`, {headers}));
};


export const cadNoticia = async (data: any) => {    
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.post('/noticias', data, {headers}));
};

export const altNoticia = async (id: number, data: any) => {

    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/noticias/atualizar/${id}`, data, {headers}));
};

export const delNoticia = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    }; 
    return (await http.delete(`/noticias/${id}`, {headers})).status;
};


export const uploadFoto = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken(),
    };
    return (await httpMultiPart.put(`/noticias/upload?id=${id}`, data, {headers}));
};