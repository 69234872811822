import React from 'react';
import style from './Buscador.module.scss';
import tema from 'styles/Tema.module.scss';
import { CgSearch } from 'react-icons/cg';

interface Props {
    busca: string,
    setBusca: React.Dispatch<React.SetStateAction<string>>;
}

export default function Buscador({ busca, setBusca }: Props) {
    return (
        <div className={tema.boxBuscador}>
            <div className={tema.buscador}>
                <input
                    value={busca}
                    onChange={evento => setBusca(evento.target.value)}
                    placeholder="Buscar"
                >
                </input>
                <CgSearch 
                    size={20}
                    color="#4C4D5F"
                />
            </div>
        </div>
    );
}