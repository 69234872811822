import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getDados = async () => {
    return (await http.get('/dado'));
};

export const getDadoById = async(id: number) => {
    return (await http.get(`/dado/details/${id}`));
};

export const getDadoByNome = async(nome: string) => {
    return (await http.get(`/dado?nomeDado=${nome}`));
};

export const cadDado = async (data: any, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    };
    return (await http.post('/dado', data, {headers}));
};

export const altDado = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/dado/${id}`, data, {headers}));
};

export const delDado = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    };
    return (await http.delete(`/dado/${id}`, {headers})).status;
};