import style from './Pesquisa.module.scss';
import Buscador from '../Buscador';
import { useState } from 'react';
import Lista from '../Lista';
import BotaoTela from 'components/Botao/BotaoTela';
import Titulo from 'components/Titulo';

export default function Pesquisa() {
     
    const [busca, setBusca] = useState('');
    const [filtro, setFiltro] = useState<number | null>(null);
    const [ordenador, setOrdenador] = useState('');

    return(
        <section className={style.pesquisa}>
            
            <Titulo titulo={'Lista de Serviços'} color={'black'} />

            <Buscador 
                busca={busca} 
                setBusca={setBusca}
            />
            
            <Lista busca={busca} filtro={filtro} ordenador={ordenador}/>
            
            <BotaoTela to={'/admin/servicos'} texto={'Voltar'} icon={'Voltar'}/>
            
        </section>
    );
}