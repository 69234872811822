import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//Componentes
import Inicio from 'pages/HomePage';
import DefaultPage from 'components/DefaultPage';
import NotFound from 'pages/NotFound';
import MessageCadastrado from 'pages/MessageCadastrado';

//Login e Auth
import Login from 'pages/Login';
import UsuarioRecuperarSenha from 'pages/Usuario/Recuperar';

//HomePage
import HomePage from 'pages/HomePage/Admin';
import ServicoHomePage from 'pages/Servico/Inicio/Alterar';
import SobreHomePage from 'pages/Sobre/';

//Admin
import AdminOutlet from 'pages/PainelAdmin';
import Admin from 'pages/PainelAdmin/Admin';

//Sobre
import SobreQuemSomos from 'pages/Sobre/QuemSomos/Alterar';
import SobreQuemSomosPage from 'pages/Sobre/QuemSomos/Page';
import SobreClientes from 'pages/Sobre/Clientes/Alterar';
import SobreAtuacao from 'pages/Sobre/Atuacao/Alterar';
import SobreAtuacaoPage from 'pages/Sobre/Atuacao/Page';

//Usuario
import Usuario from 'pages/Usuario';
import UsuarioCadastrar from 'pages/Usuario/Cadastrar';
import UsuarioPesquisar from 'pages/Usuario/Pesquisa';
import UsuarioAlterar from 'pages/Usuario/Alterar';
import UsuarioExcluir from 'pages/Usuario/Excluir';

//Notícia
import Noticia from 'pages/Noticia';
import Noticias from 'pages/Noticia/Page';
import NoticiaCadastrar from 'pages/Noticia/Cadastrar';
import NoticiaPesquisar from 'pages/Noticia/Pesquisa';
import NoticiaAlterar from 'pages/Noticia/Alterar';
import NoticiaExcluir from 'pages/Noticia/Excluir';
import NoticiaDetails from 'pages/Noticia/Details';
import NoticiaRemoverImagens from 'pages/Noticia/Imagens/Remover';

//Funcionario
import Funcionario from 'pages/Funcionario';
import Funcionarios from 'pages/Funcionario/Page';
import FuncionarioCadastrar from 'pages/Funcionario/Cadastrar';
import FuncionarioPesquisar from 'pages/Funcionario/Pesquisa';
import FuncionarioAlterar from 'pages/Funcionario/Alterar';
import FuncionarioExcluir from 'pages/Funcionario/Excluir';
import FuncionarioDetails from 'pages/Funcionario/Details';

import ServicoSite from 'pages/Servico/Page';
import ServicoAlterarSite from 'pages/Servico/Inicio/Alterar';
import Servico from 'pages/Servico';
import ServicoCadastrar from 'pages/Servico/Cadastrar';
import ServicoPesquisar from 'pages/Servico/Pesquisa';
import ServicoAlterar from 'pages/Servico/Alterar';
import ServicoExcluir from 'pages/Servico/Excluir';

import InstitucionalSite from 'pages/Institucional/Page';
import Institucional from 'pages/Institucional';
import InstitucionalAlterar from 'pages/Institucional/Alterar';

import OndeEstamosSite from 'pages/OndeEstamos/Page';
import OndeEstamos from 'pages/OndeEstamos';
import OndeEstamosAlterar from 'pages/OndeEstamos/Alterar';

import Contato from 'pages/Contato';
import ContatoAlterar from 'pages/Contato/Alterar';

import ClienteSite from 'pages/Cliente/Page';
import Cliente from 'pages/Cliente';
import ClienteAlterar from 'pages/Cliente/Alterar';

import ClienteCrudPesquisar from 'pages/Cliente/Cadastro/Pesquisa';
import ClienteCrudCadastrar from 'pages/Cliente/Cadastro/Cadastrar';
import ClienteCrudAlterar from 'pages/Cliente/Cadastro/Alterar';
import ClienteCrudExcluir from 'pages/Cliente/Cadastro/Excluir';

import TrabalheSite from 'pages/Trabalhe/Page';
import Trabalhe from 'pages/Trabalhe';
import TrabalheAlterar from 'pages/Trabalhe/Alterar';

import Dado from 'pages/Dado';
import DadoAlterar from 'pages/Dado/Alterar';

import Depoimento from 'pages/Depoimento/Index';
import DepoimentoPesquisar from 'pages/Depoimento/Pesquisa';
import DepoimentoAlterar from 'pages/Depoimento/Alterar';
import DepoimentoCadastrar from 'pages/Depoimento/Cadastrar';

export default function AppRouter() {
    return (
        <main className={'customContainer'}>
            <Router>
                <Routes>
                    <Route path='' element={<DefaultPage />}>
                        <Route index element={<Inicio />} />

                        <Route path='login/' element={<Login />} />
                        <Route path='login/recuperar-senha' element={<UsuarioRecuperarSenha />} />

                        <Route path='cadastro-realizado' element={<MessageCadastrado />} />
                        <Route path='cadastro-realizado/1' element={<MessageCadastrado />} />
                        <Route path='cadastro-realizado/2' element={<MessageCadastrado />} />

                        <Route path='inicio' element={<Inicio />} />

                        <Route path='noticias/' element={<Noticias />} />
                        <Route path='noticias/detail/:id' element={<NoticiaDetails/>}/>

                        <Route path='cliente/' element={<ClienteSite />} />
                        <Route path='trabalhe/' element={<TrabalheSite />} />
                        <Route path='ondeEstamos/' element={<OndeEstamosSite />} />
                        <Route path='quemSomos/' element={<SobreQuemSomosPage />} />
                        <Route path='atuacao/' element={<SobreAtuacaoPage />} />
                        <Route path='institucional/' element={<InstitucionalSite />} />
                        <Route path='servico/' element={<ServicoSite />} />
                        <Route path='contato/' element={<Funcionarios />} />
                        <Route path='contato/detail/:id' element={<FuncionarioDetails />} />

                    </Route>

                    <Route path='admin/' element={<AdminOutlet />} >

                        <Route path='admin/painel/' element={<Admin/>}/>
                        <Route path='painel/' element={<Admin/>}/>

                        <Route path='paginaInicial/' element={<ServicoHomePage/>}/>
                        <Route path='homePage/' element={<HomePage/>}/>

                        <Route path='admin/usuarios/' element={<Usuario/>}/>
                        <Route path='usuarios/' element={<Usuario/>}/>
                        <Route path='usuarios/cadastrar' element={<UsuarioCadastrar />} />
                        <Route path='usuarios/pesquisar' element={<UsuarioPesquisar/>}/>
                        <Route path='usuarios/alterar/:id' element={<UsuarioAlterar/>}/>
                        <Route path='usuarios/excluir/:id' element={<UsuarioExcluir/>}/>

                        <Route path='noticias/' element={<Noticia/>}/>
                        <Route path='noticias/cadastrar' element={<NoticiaCadastrar />} />
                        <Route path='noticias/pesquisar' element={<NoticiaPesquisar/>}/>
                        <Route path='noticias/alterar/:id' element={<NoticiaAlterar/>}/>
                        <Route path='noticias/excluir/:id' element={<NoticiaExcluir/>}/>
                        <Route path='noticias/imagens/remover/:id' element={<NoticiaRemoverImagens/>}/>

                        <Route path='funcionarios/' element={<Funcionario/>}/>
                        <Route path='funcionarios/cadastrar' element={<FuncionarioCadastrar />} />
                        <Route path='funcionarios/pesquisar' element={<FuncionarioPesquisar/>}/>
                        <Route path='funcionarios/alterar/:id' element={<FuncionarioAlterar/>}/>
                        <Route path='funcionarios/excluir/:id' element={<FuncionarioExcluir/>}/>

                        <Route path='dados/' element={<Dado/>}/>
                        <Route path='dados/alterar/:id' element={<DadoAlterar/>}/>

                        <Route path='depoimentos/' element={<Depoimento/>}/>
                        <Route path='depoimentos/pesquisar' element={<DepoimentoPesquisar/>}/>
                        <Route path='depoimentos/cadastrar' element={<DepoimentoCadastrar/>}/>
                        <Route path='depoimentos/alterar/:id' element={<DepoimentoAlterar/>}/>

                        <Route path='servicos/' element={<Servico/>}/>
                        <Route path='servicos/homePage' element={<ServicoAlterarSite/>}/>
                        <Route path='servicos/cadastrar' element={<ServicoCadastrar />} />
                        <Route path='servicos/pesquisar' element={<ServicoPesquisar/>}/>
                        <Route path='servicos/alterar/:id' element={<ServicoAlterar/>}/>
                        <Route path='servicos/excluir/:id' element={<ServicoExcluir/>}/>

                        <Route path='institucional/' element={<Institucional/>}/>
                        <Route path='institucional/alterar' element={<InstitucionalAlterar/>}/>

                        <Route path='ondeEstamos/' element={<OndeEstamos/>}/>
                        <Route path='ondeEstamos/alterar' element={<OndeEstamosAlterar/>}/>

                        <Route path='trabalhe/' element={<Trabalhe/>}/>
                        <Route path='trabalhe/alterar' element={<TrabalheAlterar/>}/>

                        <Route path='contato/' element={<Contato/>}/>
                        <Route path='contato/alterar' element={<ContatoAlterar/>}/>

                        <Route path='clientes/' element={<Cliente/>}/>
                        <Route path='admin/clientes/' element={<Cliente/>}/>
                        <Route path='cliente/' element={<Cliente/>}/>
                        <Route path='cliente/page/alterar' element={<ClienteAlterar/>}/>
                        
                        <Route path='cliente/cadastrar' element={<ClienteCrudCadastrar />} />
                        <Route path='cliente/pesquisar' element={<ClienteCrudPesquisar/>}/>
                        <Route path='cliente/alterar/:id' element={<ClienteCrudAlterar/>}/>
                        <Route path='cliente/excluir/:id' element={<ClienteCrudExcluir/>}/>

                        <Route path='sobre/' element={<SobreHomePage/>}/>
                        <Route path='sobre/quemSomos' element={<SobreQuemSomos/>}/>
                        <Route path='sobre/clientes' element={<SobreClientes/>}/>
                        <Route path='sobre/atuacao' element={<SobreAtuacao/>}/>

                    </Route>

                    <Route path='*' element={<NotFound />} />

                </Routes>
            </Router>
        </main>
    );
}