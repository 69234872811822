import style from './Details.module.scss';
import { useParams } from 'react-router-dom';
import Cadastrar from '../Cadastrar';
import { getFuncionarioById } from 'services/FuncionarioDataService';
import { useEffect, useState } from 'react';
import Titulo from 'components/Titulo';
import DescricaoComponent from 'components/DescricaoComponent';
import Subtitulo from 'components/Subtitulo';
import LabelComposta from 'components/LabelComposta';
import tema from '../../../styles/Tema.module.scss';
import Botao from 'components/Botao';

export default function Details() {

    const { id } = useParams();
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [mensagem, setMensagem] = useState('');

    const [funcionario, setDados] = useState(
        {
            id: 0,
            nome: '',
            sobrenome: '',
            cargo: '',
            telefone: '',
            email: '',
            descricao: ''
        }
    );

    async function enviarEmail(evento: React.FormEvent<HTMLFormElement>) {
        evento.preventDefault();

    }

    useEffect(() => {
        getFuncionario();
    },[id]);

    async function getFuncionario() {
        const funcionario = await getFuncionarioById(Number(id));
        setDados(funcionario.data);
    }

    if (id && Number(id) === 0) {
        return <Cadastrar/>;
    }

    return (
        <div className={style.conteudo}>

            <Titulo 
                titulo={`${funcionario.nome} ${funcionario.sobrenome}`} 
                color={'black'} 
            />

            <Subtitulo 
                titulo={funcionario.cargo} 
            />

            <div className={style.conteudo__box}>

                <div className={style.conteudo__box__image}>

                    <img className={style.conteudo__box__image__img}
                        src={`https://www.grupocappelletto.com//src/assets/fotos/funcionarios/f${funcionario.id}.png`}
                        alt={'Empresa'}
                    />

                    <div className={style.conteudo__box__image__descricao}>
                        <DescricaoComponent
                            size={'medium'}
                            descricao={funcionario.descricao} 
                            textalign={'left'}
                            color={'dark'}
                        />
                    </div>
                </div>
                
                <div className={style.conteudo__box__descricao}>

                    <hr/>

                    <LabelComposta key={funcionario.id} label={'Telefone'} texto={funcionario.telefone} />
                    <LabelComposta key={funcionario.id} label={'E-mail'} texto={funcionario.email} />

                    <hr/>
                    <div className={style.conteudo__box__descricao__email}>

                        <Titulo 
                            titulo={`Envie uma mensagem para ${funcionario.nome}.`} 
                            color={'black'}
                            align={'left'}
                        />

                        <form onSubmit={enviarEmail} className={style.conteudo__box__descricao__email__form}>
                            <p>  
                                <label className={tema.label}>
                                Nome
                                </label>
                            </p>
                            <p>    
                                <input
                                    className={tema.input}
                                    type="text"
                                    value={nome}
                                    maxLength={70}
                                    required
                                    placeholder="Digite seu nome"
                                    onChange={(e) => setNome(e.target.value)}
                                />
                            </p>
                            <p>  
                                <label className={tema.label}>
                                E-mail
                                </label>
                            </p>
                            <p>    
                                <input
                                    className={tema.input}
                                    type="text"
                                    value={email}
                                    maxLength={100}
                                    required
                                    placeholder="Digite seu e-mail"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </p>
                            <p>
                                <label className={tema.label}>
                                Mensagem
                                </label>
                                <textarea
                                    className={tema.inputText}
                                    value={mensagem}
                                    rows={5}
                                    cols={33}
                                    required
                                    placeholder="Digite sua mensagem"
                                    onChange={(e) => setMensagem(e.target.value)}
                                />
                            </p>   
                            <Botao 
                                type="submit"
                                formato={'blue'}
                            >
                                Enviar
                            </Botao>
                        </form>        
                    </div>
                </div>
            </div>
        </div>
    );
}