import style from './Alterar.module.scss';
import tema from 'styles/Tema.module.scss';
import Botao from 'components/Botao';
import { useEffect, useState } from 'react';
import { altCliente, getClienteDetails} from 'services/ClienteDataService';
import { useNavigate } from 'react-router-dom';
import { isUserLogged } from 'common/utils/authUtils';
import Voltar from 'components/Voltar';
import Titulo from 'components/Titulo';
import Message from 'components/Message';

export default function Alterar() {

    const [cliente, setDados] = useState(
        {
            'id': '',
            'titulo': '',
            'subtitulo': '',
            'descricao': ''
        }
    );

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [id, setId] = useState('');
    const [titulo, setTituLo] = useState('');
    const [subtitulo, setSubtitulo] = useState('');
    const [descricao, setDescricao] = useState('');

    const navigate = useNavigate();
    function verificaClienteLogado() {
        if (isUserLogged() === false) {
            return navigate('/login');
        }
    }

    useEffect(() => {
        verificaClienteLogado();
        getCliente();
    },[]);

    async function getCliente() {
        const cliente = await getClienteDetails();
        setId(cliente.data.id);
        setDados(cliente.data);
        setTituLo(cliente.data.titulo);
        setSubtitulo(cliente.data.subtitulo);
        setDescricao(cliente.data.descricao);
    }


    async function alterar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await altCliente(
            Number(id),
            {
                'titulo': titulo,
                'subtitulo': subtitulo,
                'descricao': descricao.toLowerCase().trim()
            }
        ).then((sucesso) => {

            setMessage('Cliente alterado com sucesso.');
            setTypeMessage(1);

            localStorage.setItem('userMail', descricao);

            navigate('/cadastro-realizado/2');
        }).catch((erro) => {
            const message = erro.message;
            setTypeMessage(2);  
        });
    }

    return (
        <>
            <Message type={1} message={message} />

            <Titulo titulo={'Alterar página de clientes'} color={'black'} />

            <form onSubmit={alterar} className={style.alterar}>

                <div className={tema.boxDefault}>
                    <p>  
                        <label className={tema.label}>
                        Título
                        </label>
                    </p>
                    <input
                        className={tema.input}
                        type="text"
                        value={titulo}
                        maxLength={70}
                        required
                        placeholder="Título"
                        onChange={(e) => setTituLo(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Subtítulo
                        </label>
                    </p>

                    <input
                        className={tema.input}
                        type="text"
                        value={subtitulo}
                        required
                        maxLength={70}
                        placeholder="Subtitulo do cliente"
                        onChange={(e) => setSubtitulo(e.target.value)}
                    />
                </div>                
                
                <footer className={tema.footer}>
                    <Voltar/>
                    <Botao type="submit">
                        Alterar
                    </Botao>
                </footer>
            </form>
        </>
    );
}