import { isLoadBroken, isUserLogged } from 'common/utils/authUtils';
import BotaoQuadradinho from 'components/Botao/BotaoQuadradinho';
import Titulo from 'components/Titulo';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUsuarioByEmail } from 'services/UsuarioDataService';
import style from './Admin.module.scss';

export default function Admin() {

    const navigate = useNavigate();

    const userMail = localStorage.getItem('userMail');

    const [idUsuario, setIdUsuario] = useState('');
    const [book, setBook] = useState('');
    const [nomeUsuario, setNomeUsuario] = useState('');

    function verificaUsuarioLogado() {
        if (isUserLogged() === false) {
            return navigate('/login');
        }
    }

    useEffect( () => {
        verificaUsuarioLogado();
        if (isLoadBroken()) {
            return navigate('/login');
        }
        getUsuarioLogado();
    });

    async function getUsuarioLogado() {

        if (!isUserLogged()) {
            return navigate('/login');
        }
        else {
            const response = await getUsuarioByEmail(userMail);
            const userDados = response.data.content;
            setIdUsuario(userDados[0].id);
            setBook(userDados[0].book);
            setNomeUsuario(userDados[0].nome);
        }
    }

    async function logout(evento: React.FormEvent<HTMLFormElement>) {
        localStorage.clear();
        navigate('/login');
    }

    return (
        <form className={style.admin} onSubmit={logout}>
            <Titulo titulo={`Bem vindo, ${nomeUsuario}.`} color={'black'}/>
            <div className={style.admin__box}>
                
                <BotaoQuadradinho texto={'Página Inicial'} to={'/admin/homePage'} icon={'Site'}/>
                <BotaoQuadradinho texto={'Institucional'} to={'/admin/institucional'} icon={'Empresas'}/>
                <BotaoQuadradinho texto={'Onde Estamos'} to={'/admin/ondeEstamos'} icon={'Onde Estamos'}/>
                <BotaoQuadradinho texto={'Clientes'} to={'/admin/clientes'} icon={'Clientes'}/>
                <BotaoQuadradinho texto={'Serviços'} to={'/admin/servicos'} icon={'Servicos'}/>
                <BotaoQuadradinho texto={'Funcionários'} to={'/admin/funcionarios'} icon={'Funcionario'}/>
                <BotaoQuadradinho texto={'Contato'} to={'/admin/contato'} icon={'Contato'}/>
                <BotaoQuadradinho texto={'Notícias'} to={'/admin/noticias'} icon={'Noticias'}/>
                <BotaoQuadradinho texto={'Trabalhe Conosco'} to={'/admin/trabalhe'} icon={'Trabalhe'}/>
                <BotaoQuadradinho texto={'Usuários'} to={'/admin/usuarios'} icon={'Usuario'} />

                <button 
                    type={'submit'} 
                    className={style.admin__box__sair}
                >
                    Sair do sistema
                </button>
                
            </div>
        </form>
    );
}