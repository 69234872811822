import style from './HomePage.module.scss';
import tema from 'styles/Tema.module.scss';
import BotaoTela from 'components/Botao/BotaoTela';
import Titulo from 'components/Titulo';

export default function HomePage(){
    return (
        <div className={tema.customContainer}>

            <Titulo titulo={'Página Inicial'} color={'black'} />

            <section className={style.homePage}>

                <BotaoTela
                    to={'/admin/noticias/'}
                    texto={'Notícias'}
                    icon={'Notícias'}
                />
                
                <BotaoTela 
                    to={'/admin/servicos/homePage'}
                    texto={'Nossos Serviços'}
                    icon={'Servicos'}
                />

                <BotaoTela 
                    to={'/admin/sobre'}
                    texto={'Excelência e Atuação'}
                    icon={'Info'}
                />
                
                <BotaoTela 
                    to={'/admin/dados/'}
                    texto={'Dados da empresa'}
                    icon={'Empresas'}
                />

                <BotaoTela 
                    to={'/admin/depoimentos'}
                    texto={'Depoimentos'}
                    icon={'Clientes'}
                />

            </section>
        </div>
    );
}