import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import style from './Alterar.module.scss';
import tema from 'styles/Tema.module.scss';
import Botao from 'components/Botao';
import { useEffect, useState } from 'react';
import { altNoticia, getNoticiaById, uploadFoto} from 'services/NoticiaDataService';
import { useNavigate, useParams } from 'react-router-dom';
import { isUserLogged } from 'common/utils/authUtils';
import Voltar from 'components/Voltar';
import Titulo from 'components/Titulo';
import Message from 'components/Message';
import BotaoLink from 'components/Botao/BotaoLink';
import { Link } from 'react-router-dom';

export default function Alterar() {

    let { id } = useParams();

    const [noticia, setDados] = useState(
        {
            'titulo': '',
            'subtitulo': '',
            'descricao': '',
            'indiceImagens': 0
        }
    );

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [titulo, setTituLo] = useState('');
    const [subtitulo, setSubtitulo] = useState('');
    const [descricao, setDescricao] = useState('');

    const imagens = [{original: '', thumbnail: ''}];

    const navigate = useNavigate();
    function verificaNoticiaLogado() {
        if (isUserLogged() === false) {
            return navigate('/login');
        }
    }

    function verifId() {
        return id?.startsWith('a');
    }

    useEffect(() => {
        if (verifId()) {
            setMessage('Notícia cadastrada com sucesso.');
            id = id?.substring(1);
        }
        verificaNoticiaLogado();
        getNoticia();
    },[id]);

    async function getNoticia() {
        const noticia = await getNoticiaById(Number(id));
        setDados(noticia.data);
        setTituLo(noticia.data.titulo);
        setSubtitulo(noticia.data.subtitulo);
        setDescricao(noticia.data.descricao);
    }

    function getImagens() {

        imagens.pop();

        for (let i = 1; i <= noticia.indiceImagens; i++) {

            const imagem = {
                original: 'https://www.grupocappelletto.com//src/assets/fotos/noticias/n'+ id + '-' + i +'.png',
                thumbnail: 'https://www.grupocappelletto.com//src/assets/fotos/noticias/n'+ id + '-' + i +'.png'    
            };
            imagens.push(imagem);
        }

        return imagens;
    }

    async function alterar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await altNoticia(
            Number(id),
            {
                'titulo': titulo,
                'subtitulo': subtitulo,
                'descricao': descricao.trim()
            }
        ).then((sucesso) => {

            setMessage('Notícia alterado com sucesso.');
            setTypeMessage(1);

            localStorage.setItem('userMail', descricao);

            navigate('/cadastro-realizado/2');
        }).catch((erro) => {
            const message = erro.message;
            if (message.includes('406')) {
                setMessage('E-mail já cadastrado para outro notícia.');
            }
            setTypeMessage(2);  
        });
    }

    const onFileChangeHandler = async (e: any) => {
        
        if (e[0].size > 1048576) {
            setMessage('O tamanho da imagem não dever ultrapassar o limite máximo de 1mb.');
            setTypeMessage(2);
            return;
        }

        const fotoEmpresa = {
            selectedFile: e[0]
        };
        
        const formData = new FormData();

        formData.append('file', fotoEmpresa.selectedFile);

        if (verifId()) {

            id = id?.substring(1);
        }

        const response = await uploadFoto(Number(id), formData)
            .then((sucesso) => {
                setMessage('Imagem enviada com sucesso');
            }).catch((erro) => {
                setMessage(erro.message);
                setTypeMessage(2);
            });
    };

    return (
        <form onSubmit={alterar} className={style.alterar}>
            
            <Message type={1} message={message} />

            <Titulo titulo={'Alterar Notícia'} color={'black'} />
            
            <section className={style.alterar__form}>

                <div className={style.alterar__form__foto}>

                    <label className={style.alterar__form__foto__input__label}>

                        <input 
                            className={style.alterar__form__foto__input}
                            type='file' 
                            name='foto'
                            accept='image/jpg,image/png'
                            onChange={async (e) => 
                            {
                                await onFileChangeHandler(e.target.files);
                            }}
                        />
                        Enviar imagem 

                        <Link 
                            className={style.alterar__form__foto__remover}
                            to={`/admin/noticias/imagens/remover/${id}`} 
                            title={'Remover Imagens'}
                        >
                            Remover Imagens
                        </Link>
                    </label>

                    <div className={style.alterar__form__foto__imagem} >
                        <ImageGallery 
                            items={getImagens()} 
                            autoPlay={true}
                            disableThumbnailScroll={false}
                            showThumbnails={true}
                            showFullscreenButton={false}
                            showPlayButton={false}>
                        </ImageGallery>
                    </div>
                </div>

                <div className={style.alterar__form__campos}>
                    <p>  
                        <label className={tema.label}>
                        Título
                        </label>
                    </p>
                    <input
                        className={tema.input}
                        type='text'
                        value={titulo}
                        maxLength={70}
                        required
                        placeholder='Titulo do notícia'
                        onChange={(e) => setTituLo(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Subtitulo
                        </label>
                    </p>

                    <input
                        className={tema.input}
                        type='text'
                        value={subtitulo}
                        required
                        maxLength={70}
                        placeholder='Subtitulo do notícia'
                        onChange={(e) => setSubtitulo(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Descrição
                        </label>
                    </p>

                    <textarea
                        className={tema.inputText}
                        value={descricao}
                        rows={5}
                        cols={33}
                        required
                        placeholder='Descrição do notícia'
                        onChange={(e) => setDescricao(e.target.value)}
                    />
                    <footer className={tema.footer}>
                        <Voltar/>
                        <Botao type='submit'>
                            Alterar
                        </Botao>
                        <BotaoLink to={`/admin/noticias/excluir/${id}`} texto={'Excluir'}/>
                    </footer>
                </div>
            </section>
        </form>
    );
}