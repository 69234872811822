import style from './Dado.module.scss';
import Buscador from './Buscador';
import { useState } from 'react';
import Lista from './Lista';
import BotaoTela from 'components/Botao/BotaoTela';
import Titulo from 'components/Titulo';

export default function Dado() {
     
    const [busca, setBusca] = useState('');
    const [filtro, setFiltro] = useState<number | null>(null);
    const [ordenador, setOrdenador] = useState('');

    return(
        <section className={style.dado}>
            
            <Titulo titulo={'Lista de Dados'} color={'black'} />

            <Buscador 
                busca={busca} 
                setBusca={setBusca}
            />
            
            <Lista busca={busca} filtro={filtro} ordenador={ordenador}/>
            
            <BotaoTela to={'/admin/homePage'} texto={'Voltar'} icon={'Voltar'}/>
            
        </section>
    );
}