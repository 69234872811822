import style from './Institucional.module.scss';
import tema from 'styles/Tema.module.scss';
import BotaoTela from 'components/Botao/BotaoTela';
import Titulo from 'components/Titulo';


export default function Institucional() {
    
    return(
        <div className={tema.customContainer}>
            <Titulo titulo={'Institucional'} color={'black'} />
            <section className={style.institucional}>
                
                <BotaoTela 
                    to={'/admin/institucional/alterar'}
                    texto={'Alterar'}
                    icon={'Cadastrar'}
                />
            </section>
        </div>
    );
}