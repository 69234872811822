import DescricaoComponent from 'components/DescricaoComponent';
import TituloPequeno from 'components/TituloPequeno';
import style from './Dados.module.scss';

interface Props {
    listDados: {
        id: number,
        quantidade: number,
        tipo: number,
        descricao: string
    }[];
}


export default function Dados(props: Props) {

    function getIcon(tipo: number) {

        return tipo === 1 ? 'pessoa' : tipo === 2 ? 'box' : tipo === 3 ? 'pessoas' : tipo == 4 ? 'grid' : '';
    }

    return(
        <div className={style.bg}>

            <section className={style.box}>

                <section className={style.dados}>
                    <div className={style.first}>
                        <div className={style.first__box}>
                            
                            <TituloPequeno 
                                titulo={props.listDados[0] !== undefined ? String(props.listDados[0].quantidade) : ''} 
                                icon={props.listDados[0] !== undefined ? getIcon(props.listDados[0].tipo) : ''}
                            />

                            <DescricaoComponent
                                descricao={props.listDados[0] !== undefined ? String(props.listDados[0].descricao) : ''} 
                            />

                        </div>
                        <div className={style.first__box}>
                            
                            <TituloPequeno 
                                titulo={props.listDados[1] !== undefined ? String(props.listDados[1].quantidade) : ''} 
                                icon={props.listDados[1] !== undefined ? getIcon(props.listDados[1].tipo) : ''}
                            />

                            <DescricaoComponent
                                descricao={props.listDados[1] !== undefined ? String(props.listDados[1].descricao) : ''} 
                            />

                        </div>
                        <div className={style.first__box}>

                            <TituloPequeno 
                                titulo={props.listDados[2] !== undefined ? String(props.listDados[2].quantidade) : ''} 
                                icon={props.listDados[2] !== undefined ? getIcon(props.listDados[2].tipo) : ''}
                            />

                            <DescricaoComponent
                                descricao={props.listDados[2] !== undefined ? String(props.listDados[2].descricao) : ''} 
                            />

                        </div>
                        <div className={style.first__box}>

                            <TituloPequeno 
                                titulo={props.listDados[3] !== undefined ? String(props.listDados[3].quantidade) : ''} 
                                icon={props.listDados[3] !== undefined ? getIcon(props.listDados[3].tipo) : ''}
                            />

                            <DescricaoComponent
                                descricao={props.listDados[3] !== undefined ? String(props.listDados[3].descricao) : ''} 
                            />
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}