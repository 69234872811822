import DescricaoComponent from 'components/DescricaoComponent';
import Subtitulo from 'components/Subtitulo';
import Titulo from 'components/Titulo';
import TituloPequeno from 'components/TituloPequeno';
import { useState } from 'react';
import { getNoticias } from 'services/NoticiaDataService';
import style from './Noticia.module.scss';

import ImageGallery from 'react-image-gallery';

export default function Noticia() {

    const [template] = [[{
        id: 0,
        titulo: '',
        subtitulo: '',
        indiceImagens: 0
    }]];    

    const [listNoticias, setListNoticias] = useState(template);

    async function getListNoticias() {
        const listNoticias = await getNoticias();
        setListNoticias(listNoticias.data.content);
    }

    useState(() => {
        getListNoticias();
    });

    function getImagens(id: number, indice: number) {

        if (indice === 0) {
            indice = 1;
        }
        
        const imagens = [{original: '', thumbnail: ''}];
        imagens.pop();
    
        for (let i = 1; i <= indice; i++) {
    
            const imagem = {
                original: 'https://www.grupocappelletto.com//src/assets/fotos/noticias/n'+ id + '-' + i +'.png',
                thumbnail: 'https://www.grupocappelletto.com//src/assets/fotos/noticias/n'+ id + '-' +  i +'.png',
            };
    
            imagens.push(imagem);
        }
    
        return imagens;
    }

    return(
        <section className={style.noticia}>
            <Titulo titulo={'Notícias'} color={'black'} />
            <Subtitulo titulo={'Confira as últimas novidades sobre o Grupo Cappelletto.'} />
            
            <section className={style.noticia__lista}>
                <div className={style.first}>

                    {listNoticias.map(item => (

                        <div className={style.first__box} key={item.id}>
                        
                            <TituloPequeno
                                titulo={item.titulo} 
                                to={`/noticias/detail/${item.id}`}
                                top={'min'}
                                align={'left'}
                                color={'dark'}
                            />
                            
                            <div className={style.first__second__box} key={item.id}>

                                <div className={style.first__second__box__image}>
                                    <ImageGallery 
                                        items={getImagens(item.id, item.indiceImagens)} 
                                        autoPlay={true}
                                        disableThumbnailScroll={true}
                                        showThumbnails={false}
                                        showFullscreenButton={false}
                                        showPlayButton={false}
                                        showBullets={false}
                                        showNav={false}
                                        additionalClass={style.first__second__box__image__props}
                                    />
                                </div>

                                <div className={style.first__second__box__desc}>
                                    <DescricaoComponent 
                                        descricao={item.subtitulo} 
                                    />
                                </div>
                            </div>
                            
                        </div>
                    ))}
                </div>
            </section>
        </section>
    );
}