import DescricaoComponent from 'components/DescricaoComponent';
import Subtitulo from 'components/Subtitulo';
import Titulo from 'components/Titulo';
import TituloPequeno from 'components/TituloPequeno';
import style from './Servico.module.scss';

interface Props {
    subtitulo: string,
    listServicos: {
        id: number,
        titulo: string,
        subtitulo: string,
        descricaoResumida: string
    }[];
}

export default function Servico(props: Props) {
    return(
        <div className={style.bg}>

            <section className={style.box}>
                <Titulo titulo={'Nossos serviços'} color={'white'} size={'large'}/>
                <Subtitulo 
                    titulo={props.subtitulo !== undefined ? props.subtitulo : ''}
                    color={'white'}
                />
                <section className={style.servico}>
                    <div className={style.first}>
                        <div className={style.first__box}>
                            
                            <TituloPequeno 
                                titulo={props.listServicos[0] !== undefined ? props.listServicos[0].titulo : ''} 
                                color={'white'} 
                                icon={'truck'}
                            />

                            <DescricaoComponent
                                descricao={props.listServicos[0] !== undefined ? props.listServicos[0].descricaoResumida : ''} 
                                color={'white'}
                            />

                        </div>
                        <div className={style.first__box}>
                            
                            <TituloPequeno 
                                titulo={props.listServicos[1] !== undefined ? props.listServicos[1].titulo : ''} 
                                color={'white'} 
                                icon={'oficina'}
                            />
                            <DescricaoComponent
                                descricao={props.listServicos[1] !== undefined ? props.listServicos[1].descricaoResumida : ''} 
                                color={'white'}
                            />
                  
                        </div>
                        <div className={style.first__box}>

                            <TituloPequeno 
                                titulo={props.listServicos[2] !== undefined ? props.listServicos[2].titulo : ''} 
                                color={'white'} 
                                icon={'oficina'}
                            />
                            <DescricaoComponent
                                descricao={props.listServicos[2] !== undefined ? props.listServicos[2].descricaoResumida : ''} 
                                color={'white'}
                            />

                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}