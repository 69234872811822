import style from './LabelComposta.module.scss';

interface Props {
    label?: string,
    texto?: string
}

export default function LabelComposta(props: Props) {
    return (
        <div className={style.labelComposta}>
            <div className={style.labelComposta__label}>
                {props.label}
            </div>     
            <div className={style.labelComposta__texto}>
                {props.texto}
            </div>
        </div>
    );
}