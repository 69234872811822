
import { http } from 'http-common';

export const getLogin = async(data: any) => {

    return (await http.post('/auth/', data));
};

export const recuperarSenha = async (data: any) => {

    return (await http.put('/auth/recuperar-senha/', data));
};
