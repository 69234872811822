import classNames from 'classnames';
import style from './Subtitulo.module.scss';

interface Props {
    titulo: string,
    color?: string,
    align?: string
}

export default function Subtitulo(props: Props) {
    return(
        <>
            <h3
                className={classNames({
                    [style.subtitulo]: true,
                    [style[`${props.color === 'white' ? 'subtitulo__white' : 'subtitulo__dark'}`]]: true,
                    [style[`${props.align === 'left' ? 'subtitulo__left' : 'subtitulo__middle'}`]]: true
                })}
            >
                {props.titulo}
            </h3>
        </>
    );
}