import DescricaoComponent from 'components/DescricaoComponent';
import Titulo from 'components/Titulo';
import TituloPequeno from 'components/TituloPequeno';
import { useState } from 'react';
import { getFuncionarios } from 'services/FuncionarioDataService';
import style from './Funcionario.module.scss';
import Subtitulo from 'components/Subtitulo';

export default function Funcionario() {

    const [template] = [[{
        id: 0,
        nome: '',
        sobrenome: '',
        cargo: ''
    }]];    

    const [listFuncionarios, setListFuncionarios] = useState(template);

    async function getListFuncionarios() {
        const listFuncionarios = await getFuncionarios();
        setListFuncionarios(listFuncionarios.data.content);
    }

    useState(() => {
        getListFuncionarios();
    });

    return(
        <section className={style.funcionario}>

            <Titulo titulo={'Confira os nossos funcionários e departamentos'} color={'black'} />
            <Subtitulo titulo={'Acesse o cartão de um dos funcionários para obter informações de contato.'} />

            <section className={style.funcionario__lista}>
                <div className={style.first}>
                    {listFuncionarios.map(item => (
                        <div className={style.first__box} key={item.id}>
                            
                            <TituloPequeno
                                titulo={`${item.nome} ${item.sobrenome}`} 
                                to={`/contato/detail/${item.id}`}
                                top={'min'}
                                align={'left'}
                                color={'dark'}
                            />

                            <Subtitulo titulo={item.cargo} align={'left'}/>

                            <div className={style.first__second__box} key={item.id}>

                                <img className={style.first__second__box__image}
                                    src={`https://www.grupocappelletto.com//src/assets/fotos/funcionarios/f${item.id}.png`}
                                    alt={'Empresa'}
                                />
                                
                                <TituloPequeno
                                    titulo={'Entrar em contato'} 
                                    to={`/contato/detail/${item.id}`}
                                />

                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </section>
    );
}