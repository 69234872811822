import style from './Alterar.module.scss';
import tema from 'styles/Tema.module.scss';
import Botao from 'components/Botao';
import { useEffect, useState } from 'react';
import { altOndeEstamos, getOndeEstamosDetails, uploadFoto} from 'services/OndeEstamosDataService';
import { useNavigate } from 'react-router-dom';
import { isUserLogged } from 'common/utils/authUtils';
import Voltar from 'components/Voltar';
import Titulo from 'components/Titulo';
import Message from 'components/Message';
import ReactQuill from 'react-quill';

export default function Alterar() {

    const [ondeEstamos, setDados] = useState(
        {
            'id': '',
            'titulo': '',
            'subtitulo': '',
            'descricao': ''
        }
    );

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [id, setId] = useState('');
    const [titulo, setTituLo] = useState('');
    const [subtitulo, setSubtitulo] = useState('');
    const [descricao, setDescricao] = useState('');

    const navigate = useNavigate();
    function verificaOndeEstamosLogado() {
        if (isUserLogged() === false) {
            return navigate('/login');
        }
    }


    useEffect(() => {
        verificaOndeEstamosLogado();
        getOndeEstamos();
    },[]);

    async function getOndeEstamos() {
        const ondeEstamos = await getOndeEstamosDetails();
        setId(ondeEstamos.data.id);
        setDados(ondeEstamos.data);
        setTituLo(ondeEstamos.data.titulo);
        setSubtitulo(ondeEstamos.data.subtitulo);
        setDescricao(ondeEstamos.data.descricao);
    }


    async function alterar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await altOndeEstamos(
            Number(id),
            {
                'titulo': titulo,
                'subtitulo': subtitulo,
                'descricao': descricao.toLowerCase().trim()
            }
        ).then((sucesso) => {

            setMessage('OndeEstamos alterado com sucesso.');
            setTypeMessage(1);

            localStorage.setItem('userMail', descricao);

            navigate('/cadastro-realizado/2');
        }).catch((erro) => {
            const message = erro.message;
            setTypeMessage(2);  
        });
    }

    const onFileChangeHandler = async (e: any) => {
        
        if (e[0].size > 1048576) {
            setMessage('O tamanho da imagem não dever ultrapassar o limite máximo de 1mb.');
            setTypeMessage(2);
            return;
        }

        const fotoEmpresa = {
            selectedFile: e[0]
        };
        
        const formData = new FormData();
        
        formData.append('file', fotoEmpresa.selectedFile);

        const response = await uploadFoto(Number(id), formData)
            .then((sucesso) => {
                setMessage('Imagem enviada com sucesso');
            }).catch((erro) => {
                setMessage(erro.message);
                setTypeMessage(2);
            });
    };

    return (
        <form onSubmit={alterar} className={style.alterar}>
            
            <Message type={1} message={message} />

            <Titulo titulo={'Alterar o cadastro \'Onde Estamos\''} color={'black'} />

            <section className={style.alterar__form}>

                <div className={style.alterar__form__foto}>
                    <img className={style.alterar__form__foto__imagem} 
                        src={`https://www.grupocappelletto.com//src/assets/fotos/ondeEstamos/i${id}.png`} 
                        alt={'Imagem de onde estamos'} />

                    <label className={style.alterar__form__foto__input__label}>
                        <input 
                            className={style.alterar__form__foto__input}
                            type="file" 
                            name="foto"
                            accept='image/jpg,image/png'
                            onChange={async (e) => 
                            {
                                await onFileChangeHandler(e.target.files);
                            }}
                        />
                        Enviar imagem 
                    </label>
                </div>

                <div className={style.alterar__form__text}>
                    
                    <p>  
                        <label className={tema.label}>
                            Título
                        </label>
                    </p>
                    <p>
                        <input
                            className={tema.input}
                            type="text"
                            value={titulo}
                            maxLength={70}
                            required
                            placeholder="Título do institucional"
                            onChange={(e) => setTituLo(e.target.value)}
                        />
                    </p>

                    <p>
                        <label className={tema.label}>
                        Subtitulo
                        </label>
                    </p>

                    <p>
                        <input
                            className={tema.input}
                            type="text"
                            value={subtitulo}
                            maxLength={300}
                            required={false}
                            placeholder="Subtitulo do institucional"
                            onChange={(e) => setSubtitulo(e.target.value)}
                        />
                    </p>
                    <p>
                        <label className={tema.label}>
                        Descrição
                        </label>
                    </p>
                    
                    <ReactQuill
                        theme={'snow'}
                        value={descricao} 
                        onChange={setDescricao} 
                        className={style.textEditor}
                    />

                </div>                
                
            </section>
            <footer className={tema.footer}>
                <Voltar/>
                <Botao type="submit">
                    Alterar
                </Botao>
            </footer>
        </form>
    );
}