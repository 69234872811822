import TituloPequeno from 'components/TituloPequeno';
import { Link } from 'react-router-dom';
import style from './Footer.module.scss';

export default function Footer() {

    return (
        <footer className={style.bg}>

            <section className={style.box}>

                <section className={style.footer}>
                    <div className={style.first}>
                        <div className={style.first__box}>
                            
                            <TituloPequeno
                                titulo={'Institucional'} 
                                color={'white'} 
                                to={'/institucional'}
                                reload={true}
                            />
                            <TituloPequeno
                                titulo={'Serviços'} 
                                color={'white'} 
                                to={'/servico'}
                                reload={true}
                            />
                            <TituloPequeno
                                titulo={'Trabalhe conosco'} 
                                color={'white'} 
                                to={'/trabalhe'}
                                reload={true}
                            />

                        </div>
                        <div className={style.first__whitebox}>
                            <Link to={'/'}>
                                <img className={style.first__whitebox__img} 
                                    src={require('../../assets/img/logo-comp-h.png')}
                                    alt={'Clientes'}
                                />
                            </Link>
                        </div>
                        <div className={style.first__box}>

                            <TituloPequeno 
                                titulo={'Contato'} 
                                color={'white'} 
                                icon={'fone'}
                                to={'/contato'}
                                reload={true}
                            />

                            <TituloPequeno 
                                titulo={'Onde estamos'} 
                                color={'white'} 
                                icon={'local'}
                                to={'/ondeEstamos'}
                                reload={true}
                            />

                            <TituloPequeno 
                                titulo={'Área restrita'} 
                                color={'white'} 
                                to={'/login'}
                                icon={'local'}
                                reload={true}
                            />
                           
                        </div>
                    </div>
                </section>
            </section>
            <Link className={style.footer__copyright} to={'/'}> Desenvolvido por Trice Sistemas. Todos os direitos reservados.</Link>
        </footer>
    );
}