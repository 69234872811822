import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import style from './Menu.module.scss';

export default function Menu() {
    return (
        <section>
            <Navbar className={style.menu} expand='lg' bg='dark' variant='dark' >
                <Container>
                    <Navbar.Brand className={style.menu__title} href='#home'></Navbar.Brand>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                    <Navbar.Collapse id='basic-navbar-nav'>
                        <Nav className={style.menu__link}>
                            <Nav.Link><Link className={style.menu__link__flex} to={'/admin/painel'}>Painel</Link></Nav.Link>
                            <Nav.Link><Link className={style.menu__link__flex} to={'/admin/institucional'}>Institucional</Link></Nav.Link>
                            <Nav.Link><Link className={style.menu__link__flex} to={'/admin/ondeEstamos'}>Onde Estamos</Link></Nav.Link>
                            <Nav.Link><Link className={style.menu__link__flex} to={'/admin/clientes'}>Clientes</Link></Nav.Link>
                            <Nav.Link><Link className={style.menu__link__flex} to={'/admin/servicos'}>Serviços</Link></Nav.Link>
                            <Nav.Link><Link className={style.menu__link__flex} to={'/admin/funcionarios'}>Funcionarios</Link></Nav.Link>
                            <Nav.Link><Link className={style.menu__link__flex} to={'/admin/contato'}>Contato</Link></Nav.Link>
                            <Nav.Link><Link className={style.menu__link__flex} to={'/admin/noticias'}>Notícias</Link></Nav.Link>
                            <Nav.Link><Link className={style.menu__link__flex} to={'/admin/trabalhe'}>Trabalhe Conosco</Link></Nav.Link>
                            <Nav.Link><Link className={style.menu__link__flex} to={'/admin/usuarios'}>Usuários</Link></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </section>
    );
}