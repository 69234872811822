import style from './Recuperar.module.scss';
import tema from 'styles/Tema.module.scss';
import Botao from 'components/Botao';
import { useEffect, useState } from 'react';
import { getUsuarioById} from 'services/UsuarioDataService';
import Message from 'components/Message';
import { useNavigate, useParams } from 'react-router-dom';
import BotaoLink from 'components/Botao/BotaoLink';
import { recuperarSenha } from 'services/LoginDataService';
import MessageAbsolute from 'components/Message/MessageAbsolute';

export default function Recuperar() {

    const { id } = useParams();

    const [templateCongregacao] = [[{
        id: 0,
        nome: '',
        logradouro: '',
    }]];

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [email, setEmail] = useState('');

    const navigate = useNavigate();

    async function recuperar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await recuperarSenha(
            {
                'email': email.toLowerCase().trim()
            }
        ).then((sucesso) => {

            setMessage(`E-mail de recuperação enviado com sucesso para ${email}.`);
            setTypeMessage(1);
            setEmail('');

        }).catch((erro) => {

            //const message = erro.message;
            setMessage('Ocorreu um erro ao enviar o e-mail para a sua conta, o e-mail informado está correto?');
            setTypeMessage(2);  

        });
    }

    return (
        <form onSubmit={recuperar} className={style.senha} >
            <MessageAbsolute message={message} type={typeMessage} />
            <h3 className={style.titulo}>Recuperar senha</h3>
            <div className={tema.boxDefault}>
                <p>
                    <label className={tema.label}>
                    E-mail para recuperação
                    </label>
                </p>
                <input
                    className={tema.input}
                    type="text"
                    value={email}
                    required
                    placeholder="Digite o e-mail para recuperação da conta"
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>                
            
            <footer className={tema.footer}>
                
                <BotaoLink to={'/usuario/painel'} texto={'Voltar'} />
                <Botao type="submit">
                    Alterar
                </Botao>
            </footer>
        </form>
    );
}