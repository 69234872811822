
import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getContatos = async () => {
    return (await http.get('/contato'));
};

export const getContatoDetails = async() => {

    return (await http.get('/contato/details'));
};

export const cadContato = async (data: any) => {    
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.post('/contato', data, {headers}));
};

export const altContato = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/contato/${id}`, data, {headers}));
};

export const delContato = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    }; 
    return (await http.delete(`/contato/${id}`, {headers})).status;
};

export const uploadFoto = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/contato/upload?id=${id}`, data, {headers}));
};