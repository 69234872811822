import classNames from 'classnames';
import style from './Titulo.module.scss';

interface Props {
    titulo: string,
    color?: string,
    size?: string,
    align?: string
}

export default function Titulo(props: Props) {
    return(
        <>
            <h3 
                className={
                    classNames({
                        [style.titulo]: true,
                        [style[`${props.color === 'black' ? 'titulo__black' : 'titulo__white'}`]]: true,
                        [style[`${props.size === 'large' ? 'titulo__large' : props.size === 'small' ? 'titulo__small' : 'titulo__middle'}`]]: true,
                        [style[`${props.align ? props.align === 'center' ?  'titulo__align__center' : 'titulo__align__left' : 'titulo__align__center'}`]]: true
                    })
                }
            >
                {props.titulo}
            </h3>
        </>
    );
}