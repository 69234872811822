import TituloPequeno from 'components/TituloPequeno';
import { Link } from 'react-router-dom';
import style from './Footer.module.scss';

export default function Footer() {

    return (
        <footer className={style.bg}>
            <section className={style.box}>
                <Link className={style.box__copyright} to={'/'}> Desenvolvido por Trice Sistemas. Todos os direitos reservados.</Link>
            </section>
        </footer>
    );
}