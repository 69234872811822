import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getClientePage = async () => {
    return (await http.get('/clientes'));
};

export const getClientePageById = async(id: number) => {
    return (await http.get(`/clientes/details/${id}`));
};

export const getClienteByEmail = async(email: string | null) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get(`/clientes/email?email=${email}`, {headers}));
};

export const cadClientePage = async (data: any) => {
    return (await http.post('/clientes', data));
};

export const altClientePage = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/clientes/${id}`, data, {headers}));
};

export const altSenha = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/clientes/alterar-senha/${id}`, data, {headers}));
};

export const aprovarCliente = async (id: number, data: any, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    };
    return (await http.put(`/clientes/aprovar/${id}`, data, {headers}));
};

export const delClientePage = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    };
    return (await http.delete(`/clientes/${id}`, {headers})).status;
};

export const reportarErro = async (data: any) => {
    return (await http.post('/clientes/reportar-erro/', data));
};


export const getClientes = async () => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get('/cliente', {headers}));
};

export const getClienteDetails = async() => {
    return (await http.get('/cliente/details'));
};


export const getClienteByTitulo = async(titulo: string | null) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get(`/cliente/titulo?titulo=${titulo}`, {headers}));
};


export const cadCliente = async (data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.post('/cliente', data, {headers}));
};

export const altCliente = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/cliente/${id}`, data, {headers}));
};

export const delCliente = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    };
    return (await http.delete(`/cliente/${id}`, {headers})).status;
};
