import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import style from './Voltar.module.scss';

interface Props {
    to?: string
}

export default function Voltar(props: Props){

    const navigate = useNavigate();

    return(
        <div className={style.voltar}>
            <div className={style.voltar__box}>
                <button
                    type={'button'}
                    className={style.voltar__confirmar}
                    onClick={() => navigate(-1)}
                >
                    Voltar
                </button>
            </div>
        </div>
    );
}