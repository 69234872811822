import style from './Item.module.scss';
import tema from 'styles/Tema.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
    id: number,
    titulo: string,
    exibirSite: string
}

export default function Item(props: Props) {

    const{ id, titulo, exibirSite} = props;
    const navigate = useNavigate();

    return (
        <>
            <tr className={style.item} onClick={() => navigate(`/admin/servicos/alterar/${id}`)}>
                <td>
                    <div className={style.item__descricao}>
                        <div className={tema.tituloLista}>
                            <h2>{titulo}</h2>
                        </div>
                    </div>
                </td>
                <td>
                    <div className={style.item__descricao}>
                        <div className={tema.tituloLista}>
                            <h2>{exibirSite === 'S' ? 'Sim' : 'Não'}</h2>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    );
}