import style from './Alterar.module.scss';
import tema from 'styles/Tema.module.scss';
import Botao from 'components/Botao';
import { useEffect, useState } from 'react';
import { altContato, getContatoDetails, uploadFoto} from 'services/ContatoDataService';
import { useNavigate } from 'react-router-dom';
import { isUserLogged } from 'common/utils/authUtils';
import Voltar from 'components/Voltar';
import Titulo from 'components/Titulo';
import Message from 'components/Message';

export default function Alterar() {

    const [contato, setDados] = useState(
        {
            'id': '',
            'telefone1': '',
            'telefone2': '',
            'telefone3': '',
            'email1': '',
            'email2': '',
            'email3': '',
            'endereco': ''
        }
    );

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [id, setId] = useState('');
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [email3, setEmail3] = useState('');
    const [telefone1, setTelefone1] = useState('');
    const [telefone2, setTelefone2] = useState('');
    const [telefone3, setTelefone3] = useState('');
    const [endereco, setEndereco] = useState('');

    const navigate = useNavigate();
    function verificaContatoLogado() {
        if (isUserLogged() === false) {
            return navigate('/login');
        }
    }


    useEffect(() => {
        verificaContatoLogado();
        getContato();
    },[]);

    async function getContato() {
        const contato = await getContatoDetails();
        setId(contato.data.id);
        setDados(contato.data);
        setTelefone1(contato.data.telefone1);
        setTelefone2(contato.data.telefone2);
        setTelefone3(contato.data.telefone3);
        setEmail1(contato.data.email1);
        setEmail2(contato.data.email2);
        setEmail3(contato.data.email3);
        setEndereco(contato.data.endereco);
    }


    async function alterar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await altContato(
            Number(id),
            {
                'telefone1': telefone1,
                'telefone2': telefone2,
                'telefone3': telefone3,
                'email1': email1,
                'email2': email2,
                'email3': email3,
                'endereco': endereco
            }
        ).then((sucesso) => {
            setMessage('Contato alterado com sucesso.');
            setTypeMessage(1);
            navigate('/cadastro-realizado/2');
        }).catch((erro) => {
            const message = erro.message;
            setTypeMessage(2);  
        });
    }

    return (
        <form onSubmit={alterar} className={style.alterar}>
            <Message type={1} message={message} />

            <Titulo titulo={'Alterar contato'} color={'black'} />

            <section className={style.alterar__form}>

                <div className={tema.boxDefault}>
                    <p>  
                        <label className={tema.label}>
                        Telefone
                        </label>
                        <input
                            className={tema.input}
                            type="text"
                            value={telefone1}
                            maxLength={15}
                            required
                            placeholder="Telefone"
                            onChange={(e) => setTelefone1(e.target.value)}
                        />
                    </p>


                    <p>  
                        <label className={tema.label}>
                        Telefone 2
                        </label>
                        <input
                            className={tema.input}
                            type="text"
                            value={telefone2}
                            maxLength={15}
                            required
                            placeholder="Telefone 2"
                            onChange={(e) => setTelefone1(e.target.value)}
                        />
                    </p>
                    <p>  
                        <label className={tema.label}>
                        Telefone 3
                        </label>
                        
                        <input
                            className={tema.input}
                            type="text"
                            value={telefone3}
                            maxLength={15}
                            required
                            placeholder="Telefone"
                            onChange={(e) => setTelefone1(e.target.value)}
                        />
                    </p>
                    <p>  
                        <label className={tema.label}>
                        Email
                        </label>
                    </p>
                    <p>
                        <input
                            className={tema.input}
                            type="text"
                            value={email1}
                            maxLength={100}
                            required
                            placeholder="Email"
                            onChange={(e) => setEmail1(e.target.value)}
                        />
                    </p>
                    <p>  
                        <label className={tema.label}>
                        Email 2
                        </label>
                    </p>
                    <p>
                        <input
                            className={tema.input}
                            type="text"
                            value={email2}
                            maxLength={100}
                            required
                            placeholder="Email 2"
                            onChange={(e) => setEmail1(e.target.value)}
                        />
                    </p>
                    <p>  
                        <label className={tema.label}>
                        Email 3
                        </label>
                    </p>
                    <p>    
                        <input
                            className={tema.input}
                            type="text"
                            value={email3}
                            maxLength={100}
                            required
                            placeholder="Email"
                            onChange={(e) => setEmail1(e.target.value)}
                        />
                    </p>
                    <p>
                        <label className={tema.label}>
                        Endereço
                        </label>
                        <textarea
                            className={tema.inputText}
                            value={endereco}
                            rows={5}
                            cols={33}
                            required
                            placeholder="Endereço da empresa"
                            onChange={(e) => setEndereco(e.target.value)}
                        />
                    </p>   
                </div>                
            </section>
            <footer className={tema.footer}>
                <Voltar/>
                <Botao type="submit">
                    Alterar
                </Botao>
            </footer>
        </form>
    );
}