
import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';
import { httpMultiPart } from 'http-multipart';

export const delImagem = async (id: number, index:number, data: any) => {
    
    const headers = {
        'Authorization': 'Bearer ' + getToken(),
    }; 

    return (await http.post('/imagens', data, {headers}));
};


export const uploadFoto = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken(),
    };
    return (await httpMultiPart.put(`/noticias/upload?id=${id}`, data, {headers}));
};