import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import style from './Details.module.scss';
import { useParams } from 'react-router-dom';
import Cadastrar from '../Cadastrar';
import { getNoticiaById } from 'services/NoticiaDataService';
import { useEffect, useState } from 'react';
import Titulo from 'components/Titulo';
import DescricaoComponent from 'components/DescricaoComponent';

export default function Details() {

    const { id } = useParams();

    const [noticia, setDados] = useState(
        {
            id: 0,
            titulo: '',
            subtitulo: '',
            descricao: '',
            indiceImagens: 0
        }
    );

    const imagens = [{original: '', thumbnail: ''}];

    useEffect(() => {
        getNoticia();
    },[id]);

    async function getNoticia() {
        const noticia = await getNoticiaById(Number(id));
        setDados(noticia.data);
    }

    if (id && Number(id) === 0) {

        return <Cadastrar/>;
    }


    function getImagens() {

        imagens.pop();

        for (let i = 1; i <= noticia.indiceImagens; i++) {

            const imagem = {
                original: 'https://www.grupocappelletto.com//src/assets/fotos/noticias/n'+ id + '-' + i +'.png',
                thumbnail: 'https://www.grupocappelletto.com//src/assets/fotos/noticias/n'+ id + '-' +  i +'.png'
            };
            imagens.push(imagem);
        }

        return imagens;
    }

    return (
        <div className={style.conteudo}>
            
            <Titulo titulo={noticia.titulo} color={'black'} />
            
            <DescricaoComponent 
                descricao={noticia.subtitulo} 
                textalign={'center'}
            />

            <div>

                <div className={style.conteudo__boxImage}>
                    <ImageGallery 
                        items={getImagens()} 
                        autoPlay={true}
                        disableThumbnailScroll={true}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                    />
                </div>

                <section className={style.conteudo__descricao}>
                    <DescricaoComponent 
                        descricao={noticia.descricao} 
                        textalign={'left'}
                        color={'dark'}
                        size={'large'}
                    />
                </section>
            </div>
        </div>
    );
}