import style from './OndeEstamos.module.scss';
import tema from 'styles/Tema.module.scss';
import BotaoTela from 'components/Botao/BotaoTela';
import Titulo from 'components/Titulo';


export default function OndeEstamos() {
    
    return(
        <div className={tema.customContainer}>
            <Titulo titulo={'OndeEstamos'} color={'black'} />
            <section className={style.ondeEstamos}>
                <BotaoTela 
                    to={'/admin/ondeEstamos/alterar'}
                    texto={'Alterar'}
                    icon={'Cadastrar'}
                />
            </section>
        </div>
    );
}