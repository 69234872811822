import DescricaoComponent from 'components/DescricaoComponent';
import Titulo from 'components/Titulo';
import { useEffect, useState } from 'react';
import { getSobreByTipo } from 'services/SobreDataService';
import style from './Page.module.scss';

export default function Page() {

    const [template] = [{
        id: 0,
        descricao: '',
        descricaoResumida: '',
        tipo: ''
    }];    

    const [id, setId] = useState();
    const [descricao, setDescricao] = useState();
    const [descricaoResumida, setDescricaoResumida] = useState();
    const [quemSomos, setQuemSomos] = useState(template);

    async function getQuemSomos() {
        const dataQuemSomos = await getSobreByTipo(1);
        dataQuemSomos.data.id = 1;
        setQuemSomos(dataQuemSomos.data);
        setDescricao(dataQuemSomos.data.descricao);
        setDescricaoResumida(dataQuemSomos.data.descricaoResumida);
    }

    useEffect(() => {
        getQuemSomos();
    },[id]);

    return(
        <section className={style.ondeEstamos}>
            <Titulo
                titulo={'Quem Somos?'} 
                color={'black'}
            />
            <section className={style.ondeEstamos__lista}>
                <div className={style.first}>
                    <section className={style.noticia__lista}>
                        <div className={style.first}>
                            <div className={style.first__box}>
                                <img className={style.first__box__image}
                                    src={`https://www.grupocappelletto.com//src/assets/fotos/sobre/s${quemSomos.id}.png`}
                                    alt={'Sobre'}
                                />
                            </div>
                            <div className={style.first__box__text}>
                                <DescricaoComponent
                                    descricao={quemSomos.descricao} 
                                    size={'medium'}
                                />
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </section>
    );
}