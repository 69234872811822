import style from './Noticia.module.scss';
import tema from 'styles/Tema.module.scss';
import BotaoTela from 'components/Botao/BotaoTela';
import Titulo from 'components/Titulo';


export default function Noticia() {
    
    return(
        <div className={tema.customContainer}>
            <Titulo titulo={'Notícias'} color={'black'} />
            <section className={style.noticia}>
                
                <BotaoTela 
                    to={'/admin/noticias/pesquisar'}
                    texto={'Pesquisar'}
                    icon={'Pesquisar'}
                />

                <BotaoTela 
                    to={'/admin/noticias/cadastrar'}
                    texto={'Cadastrar'}
                    icon={'Cadastrar'}
                />

            </section>
        </div>
    );
}