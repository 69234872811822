
import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getOndeEstamoss = async () => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get('/ondeestamos', {headers}));
};

export const getOndeEstamosDetails = async() => {
    return (await http.get('/ondeestamos/details'));
};


export const getOndeEstamosByTitulo = async(titulo: string | null) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get(`/ondeestamos/titulo?titulo=${titulo}`, {headers}));
};


export const cadOndeEstamos = async (data: any) => {    
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.post('/ondeestamos', data, {headers}));
};

export const altOndeEstamos = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/ondeestamos/${id}`, data, {headers}));
};

export const delOndeEstamos = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    }; 
    return (await http.delete(`/ondeestamos/${id}`, {headers})).status;
};

export const uploadFoto = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/ondeestamos/upload?id=${id}`, data, {headers}));
};