import { Link } from 'react-router-dom';
import style from './BotaoQuadradinho.module.scss';
import { FcTemplate, FcBusinessman, FcSearch, FcNews, FcPodiumWithSpeaker, 
    FcOrgUnit, FcGenealogy, FcCustomerSupport, FcOrganization, FcConferenceCall, 
    FcDiploma2 } from 'react-icons/fc';

interface Props {
    to: string,
    texto: string,
    icon?: string,
    styleAlt?: string,
    styleAlt__icon?: string
}

export default function BotaoQuadradinho(dados: Props) {
    return(
        <Link
            className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoQuadrado : dados.styleAlt}
            to={dados.to}
        >
            <div className={style.botaoQuadrado__box}>
                {dados.icon === 'Usuario' ?    
                    <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoQuadrado__icon : dados.styleAlt__icon}>
                        <FcBusinessman size={42}/> 
                    </div>
                    : dados.icon === 'Empresas' ?
                        <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoQuadrado__icon : dados.styleAlt__icon}>
                            <FcOrganization size={42}/> 
                        </div>
                        : dados.icon === 'Servicos' ?
                            <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoQuadrado__icon : dados.styleAlt__icon}>
                                <FcOrgUnit size={42}/> 
                            </div>
                            : dados.icon === 'Clientes' ?
                                <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoQuadrado__icon : dados.styleAlt__icon}>
                                    <FcConferenceCall size={42}/> 
                                </div>
                                : dados.icon === 'Noticias' ?
                                    <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoQuadrado__icon : dados.styleAlt__icon}>
                                        <FcNews size={42}/> 
                                    </div>
                                    : dados.icon === 'Pesquisar' ?
                                        <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoQuadrado__icon : dados.styleAlt__icon}>
                                            <FcSearch size={42}/> 
                                        </div>
                                        : dados.icon === 'Contato' ?
                                            <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoQuadrado__icon : dados.styleAlt__icon}>
                                                <FcCustomerSupport size={42}/> 
                                            </div>
                                            : dados.icon === 'Trabalhe' ?
                                                <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoQuadrado__icon : dados.styleAlt__icon}>
                                                    <FcDiploma2 size={42}/> 
                                                </div>
                                                : dados.icon === 'Onde Estamos' ?
                                                    <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoQuadrado__icon : dados.styleAlt__icon}>
                                                        <FcGenealogy size={42}/> 
                                                    </div>
                                                    : dados.icon === 'Site' ?
                                                        <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoQuadrado__icon : dados.styleAlt__icon}>
                                                            <FcTemplate size={42}/> 
                                                        </div>
                                                        : dados.icon === 'Funcionario' ?
                                                            <div className={dados.styleAlt === '' || dados.styleAlt === undefined ? style.botaoQuadrado__icon : dados.styleAlt__icon}>
                                                                <FcPodiumWithSpeaker size={42}/> 
                                                            </div>
                                                            :''
                }
                <div className={style.botaoQuadrado__titulo}>
                    {dados.texto} {' '}
                </div>
                
            </div>
        </Link>
    );
}