import style from './Cadastrar.module.scss';
import tema from 'styles/Tema.module.scss';
import { useEffect, useState } from 'react';
import { cadUsuario } from 'services/UsuarioDataService';
import { useNavigate } from 'react-router-dom';
import Titulo from 'components/Titulo';
import Voltar from 'components/Voltar';
import Botao from 'components/Botao';

export default function Cadastrar() {

    const navigate = useNavigate();


    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [celular, setCelular] = useState('');
    const [telefone, setTelefone] = useState('');

    async function cadastrar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await cadUsuario({
            'nome': nome,
            'sobrenome': sobrenome,
            'email': email.toLowerCase().trim(),
            'senha': senha.trim(),
            'celular': celular,
            'telefone': telefone,
            'status': 'P'
        }
        ).then((sucesso) => {

            clearForm();
            setTypeMessage(1);
            navigate('/cadastro-realizado/1');
        }).catch((erro) => {
            const message = erro.message;
            if (message.includes('406')) {
                setMessage('E-mail já cadastrado para outro usuário.');
            }
            setTypeMessage(2);  
        });
    }

    function clearForm() {
        setNome('');
        setSobrenome('');
        setEmail('');
        setSenha('');
        setCelular('');
        setTelefone('');
    }

    return (
        <section >
            <Titulo titulo={'Cadastrar Usuário'} color={'black'}/>
            <form onSubmit={cadastrar} className={style.cadastrar}>
                <div className={tema.boxDefault}>
                    <p>
                        <label className={tema.label}>
                        Nome
                        </label>
                    </p>
                    <input
                        className={tema.input}
                        type="text"
                        value={nome}
                        maxLength={70}
                        required
                        placeholder="Nome do usuário"
                        onChange={(e) => setNome(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Sobrenome
                        </label>
                    </p>

                    <input
                        className={tema.input}
                        type="text"
                        value={sobrenome}
                        required
                        maxLength={70}
                        placeholder="Sobrenome do usuário"
                        onChange={(e) => setSobrenome(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Email
                        </label>
                    </p>

                    <input
                        className={tema.input}
                        type="text"
                        value={email}
                        placeholder="Email do usuário"
                        maxLength={70}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Senha
                        </label>
                    </p>

                    <input
                        className={tema.input}
                        type="password"
                        value={senha}
                        required
                        maxLength={20}
                        onChange={(e) => setSenha(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Celular
                        </label>
                    </p>

                    <input
                        className={tema.input}
                        type="text"
                        value={celular}
                        required
                        maxLength={15}
                        placeholder="Celular do usuário"
                        onChange={(e) => setCelular(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                        Telefone
                        </label>
                    </p>
                    <input
                        className={tema.input}
                        type="text"
                        value={telefone}
                        placeholder="Telefone do usuário"
                        maxLength={15}
                        onChange={(e) => setTelefone(e.target.value)}
                    />
                </div>
                <footer className={tema.footer}>     
                    <Voltar/>
                    <Botao type="submit">
                        Cadastrar
                    </Botao>
                </footer>
            </form>
        </section>
    );
}