import style from './Alterar.module.scss';
import tema from 'styles/Tema.module.scss';
import Botao from 'components/Botao';
import { useEffect, useState } from 'react';
import { altServico, getServicoById, uploadFoto} from 'services/ServicoDataService';
import { useNavigate, useParams } from 'react-router-dom';
import { isUserLogged } from 'common/utils/authUtils';
import Voltar from 'components/Voltar';
import Titulo from 'components/Titulo';
import Message from 'components/Message';
import BotaoLink from 'components/Botao/BotaoLink';

export default function Alterar() {

    let { id } = useParams();

    const [servico, setDados] = useState(
        {
            'titulo': '',
            'descricao': '',
            'descricaoResumida': '',
            'exibirSite': ''
        }
    );

    const [message, setMessage] = useState('');
    const [typeMessage, setTypeMessage] = useState(1);
    const [titulo, setTituLo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [descricaoResumida, setDescricaoResumida] = useState('');
    const [exibirSite, setExibirSite] = useState('N');


    const navigate = useNavigate();
    function verificaServicoLogado() {
        if (isUserLogged() === false) {
            return navigate('/login');
        }
    }

    function verifId() {
        return id?.startsWith('a');
    }

    useEffect(() => {
        if (verifId()) {
            setMessage('Serviço cadastrado com sucesso.');
            id = id?.substring(1);
        }
        verificaServicoLogado();
        getServico();
    },[id]);

    async function getServico() {
        const servico = await getServicoById(Number(id));
        setDados(servico.data);
        setTituLo(servico.data.titulo);
        setDescricao(servico.data.descricao);
        setDescricaoResumida(servico.data.descricaoResumida);
        setExibirSite(servico.data.exibirSite);
    }


    async function alterar(evento: React.FormEvent<HTMLFormElement>) {

        evento.preventDefault();

        const response = await altServico(
            Number(id),
            {
                'titulo': titulo,
                'descricao': descricao.trim(),
                'descricaoResumida': descricaoResumida.trim(),
                'exibirSite': exibirSite
            }
        ).then((sucesso) => {

            setMessage('Serviço alterado com sucesso.');
            setTypeMessage(1);

            localStorage.setItem('userMail', descricao);

            navigate('/cadastro-realizado/2');
        }).catch((erro) => {
            const message = erro.message;
            if (message.includes('406')) {
                setMessage('E-mail já cadastrado para outro serviço.');
            }
            setTypeMessage(2);  
        });
    }

    const onFileChangeHandler = async (e: any) => {
        
        if (e[0].size > 1048576) {
            setMessage('O tamanho da imagem não dever ultrapassar o limite máximo de 1mb.');
            setTypeMessage(2);
            return;
        }

        const fotoEmpresa = {
            selectedFile: e[0]
        };
        
        const formData = new FormData();
        
        formData.append('file', fotoEmpresa.selectedFile);

        if (verifId()) {
            id = id?.substring(1);
        }
        const response = await uploadFoto(Number(id), formData)
            .then((sucesso) => {
                setMessage('Imagem enviada com sucesso');
            }).catch((erro) => {
                setMessage(erro.message);
                setTypeMessage(2);
            });
    };

    return (
        <>
            <Message type={1} message={message} />

            <Titulo titulo={'Alterar Serviço'} color={'black'} />

            <form onSubmit={alterar} className={style.alterar}>

                <img className={style.foto__imagem} src={`https://www.grupocappelletto.com/src/assets/fotos/pageServicos/s${id}.png`} alt={'Imagem da serviço'} />

                <label className={style.foto__input__label}>
                    <input 
                        className={style.foto__input}
                        type="file" 
                        name="foto"
                        accept='image/jpg,image/png'
                        onChange={async (e) => 
                        {
                            await onFileChangeHandler(e.target.files);
                        }}
                    />
                    Enviar imagem 
                </label>

                <div className={tema.boxDefault}>
                    <p>  
                        <label className={tema.label}>
                        Título
                        </label>
                    </p>
                    <p>
                        <input
                            className={tema.input}
                            type="text"
                            value={titulo}
                            maxLength={70}
                            required
                            placeholder="Titulo do serviço"
                            onChange={(e) => setTituLo(e.target.value)}
                        />
                    </p>
                    <p>
                        <label className={tema.label}>
                        Descrição para a página inicial
                        </label>
                    </p>
                    <p>
                        <textarea
                            className={tema.inputText}
                            value={descricaoResumida}
                            required
                            maxLength={150}
                            placeholder="Descrição resumida do serviço"
                            onChange={(e) => setDescricaoResumida(e.target.value)}
                        />
                    </p>
                    <p>
                        <label className={tema.label}>
                        Descrição completa
                        </label>
                    </p>
                    <textarea
                        className={tema.inputText}
                        value={descricao}
                        required
                        maxLength={1500}
                        placeholder="Descrição do serviço"
                        onChange={(e) => setDescricao(e.target.value)}
                    />

                    <p>
                        <label className={tema.label}>
                            Exibir na página inicial?
                        </label>
                    </p>
                    <p>
                        <select className={tema.selCombo} value={exibirSite} onChange={(e) => setExibirSite(e.target.value)}>
                            <option key='S' value='S'>
                                Sim
                            </option>
                            <option key='N' value='N'>
                                Não
                            </option>
                        </select>
                    </p>

                </div>                
                
                <footer className={tema.footer}>
                    <Voltar/>
                    <Botao type="submit">
                        Alterar
                    </Botao>
                    <BotaoLink to={`/admin/servicos/excluir/${id}`} texto={'Excluir'}/>
                </footer>
            </form>
        </>
    );
}