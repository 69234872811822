import style from './MessageAbsolute.module.scss';
import classNames from 'classnames';

interface Props {
    message: string | undefined,
    type: number
}

export default function MessageAbsolute(valor: Props) {

    return(
        <div className={
            classNames({
                [style.messageAbsolute]: true,
                [style[`${valor.message ? valor.type === 2 ? 'messageAbsolute__error' : 'messageAbsolute__success' : ''}`]]: true
            })
        }
        >
            {valor.message ? <p>{valor.message} </p> : null}
        </div>
    );
}