import Titulo from 'components/Titulo';
import style from './Apresentacao.module.scss';

export default function Apresentacao() {
    return(
        <div className={style.bg}>
            <section className={style.apresentacao}>

                <Titulo titulo={'Apresentamos, o Grupo Cappelletto'} />

                <video 
                    className={style.apresentacao__video__play}
                    autoPlay={false}
                    muted={true}
                    playsInline={true}
                    loop={true}
                    controls
                    width={1400}
                >
                    <source src={require('../../assets/video/apresentacao.mp4')} type='video/mp4' />
                </video>
            </section>
        </div>
    );
}