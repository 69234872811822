
import { getToken } from 'common/utils/authUtils';
import { http } from 'http-common';

export const getTrabalhes = async () => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.get('/trabalhe', {headers}));
};

export const getTrabalheDetails = async() => {
    return (await http.get('/trabalhe/details'));
};

export const cadTrabalhe = async (data: any) => {    
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.post('/trabalhe', data, {headers}));
};

export const altTrabalhe = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/trabalhe/${id}`, data, {headers}));
};

export const delTrabalhe = async (id: number, token: string) => {
    const headers = {
        'Authorization': 'Bearer ' + token
    }; 
    return (await http.delete(`/trabalhe/${id}`, {headers})).status;
};

export const uploadFoto = async (id: number, data: any) => {
    const headers = {
        'Authorization': 'Bearer ' + getToken()
    };
    return (await http.put(`/trabalhe/upload?id=${id}`, data, {headers}));
};